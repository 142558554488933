import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { produce } from 'immer';
import { Avatar, Listbox, ListboxItem, ListboxSection, Skeleton, Dropdown, DropdownTrigger, DropdownMenu, DropdownItem } from "@nextui-org/react";
import { getBoolean } from 'firebase/remote-config';
import { httpsCallable } from 'firebase/functions';
import { collection, where, query, orderBy, limit, getDocs } from "firebase/firestore";

import { setNewsList, setCourseList, setJobList, setBookList, setUser } from 'cvpop-redux-sdk';
import { t } from "cvpop-localization-sdk";
import { flags, icons } from 'cvpop-assets-sdk';
import { isProUser, getAvatarUser } from "cvpop-utility-sdk";
import { cvPickerItems, urls } from "cvpop-constants-sdk";

import _ from "lodash"

import { auth, db, functions, remoteConfig } from "../../../controllers/firebase";
import { errorHandler, getCountryCodeFromCity, goToPaywall, openUrl, setAppLocalization } from "../../../controllers/app";
import { useColorScheme } from '../../../controllers/hooks';

import WebIcon from "../../atoms/WebIcon";


const WebHomeSidebar = () => {

    // ------------------------------------------------------------------------------------------------------------------------
    const { user } = useSelector(state => state.userReducer)
    const { offering } = useSelector(state => state.appReducer)
    const { newsList } = useSelector(state => state.newsReducer)
    const { courseList } = useSelector(state => state.courseReducer)
    const { bookList } = useSelector(state => state.bookReducer)
    const { jobList } = useSelector(state => state.jobReducer)
    const { platform } = useParams()
    const { pathname } = useLocation()

    // ------------------------------------------------------------------------------------------------------------------------
    const n = useNavigate()
    const d = useDispatch()
    const cs = useColorScheme()
    const fbuser = auth.currentUser

    // ------------------------------------------------------------------------------------------------------
    const [appVersion, setAppVersion] = useState(null)
    const [isLanguageMenuOpen, setIsLanguageMenuOpen] = useState(false)
    const [loadingNews, setLoadingNews] = useState(true);
    const [loadingCourse, setLoadingCourse] = useState(true);
    const [loadingJob, setLoadingJob] = useState(true);
    const [loadingBook, setLoadingBook] = useState(true);

    // ------------------------------------------------------------------------------------------------------------------------
    const { displayName, uid } = fbuser || {}; //in case of logout
    const { firstName, lastName, professionalTitle, location, language } = user || {}
    const { legal } = urls

    // ------------------------------------------------------------------------------------------------------------------------
    const countryCode = useMemo(() => getCountryCodeFromCity(), []);
    const avatar = useMemo(() => getAvatarUser(user, platform) || icons.avatar, [user, platform])
    const isPro = useMemo(() => isProUser(user), [user])

    const isSalaryOn = useMemo(() => getBoolean(remoteConfig, "salarySearch"), [remoteConfig])
    const isCourseOn = useMemo(() => getBoolean(remoteConfig, "courseSearch"), [remoteConfig])
    const isJobOn = useMemo(() => getBoolean(remoteConfig, "jobSearch"), [remoteConfig])
    const isBookOn = useMemo(() => getBoolean(remoteConfig, "bookSearch"), [remoteConfig])

    const courseApi = useMemo(() => httpsCallable(functions, "getcourselist"), [functions]);
    const jobApi = useMemo(() => httpsCallable(functions, "getjoblistv2"), [functions]);
    const bookApi = useMemo(() => httpsCallable(functions, "getbooklist"), [functions]);

    const isWeb = useMemo(() => platform === "web", [platform])
    const cNav = useMemo(() => (pathname || "").split('/')[4], [pathname])

    // ------------------------------------------------------------------------------------------------------------------------
    const onAction = useCallback(k => {
        if (k === "appLanguage") return setIsLanguageMenuOpen(!isLanguageMenuOpen)
        if (k === "contactUs") return window.webkit.messageHandlers["openMail"].postMessage(uid)
        if (k === "terms" || k == "privacy") return openUrl(legal[k], platform)
        if (k === "premium") return goToPaywall(offering, platform, n)
        n(k)
    }, [uid, legal, isLanguageMenuOpen, offering, platform, n])

    const onGetAppVersion = useCallback(({ detail }) => setAppVersion(detail.data), [])

    const getNewsList = useCallback(() => {
        setLoadingNews(true)
        const ref = collection(db, "00_POSTS_00")
        d(setNewsList([]))
        getDocs(query(ref, where("blog.language", "==", ["it", "en", "es"].includes(language) ? language : "en"), orderBy("postedAt", "desc"), limit(15)))
            .then(s => d(setNewsList(s.docs.map(e => e.data()))))
            .catch(e => errorHandler("err_get_news", e, true))
            .finally(() => setLoadingNews(false))
    }, [db, language])

    const getCourseList = useCallback(() => {
        setLoadingCourse(true)
        d(setCourseList([]))
        courseApi({ jobTitle: professionalTitle, page: 1, language })
            .then(({ data }) => d(setCourseList(data.results)))
            .catch(e => errorHandler("err_search_courses", e, true))
            .finally(() => setLoadingCourse(false))
    }, [professionalTitle, language])

    const getJobList = useCallback(() => {
        setLoadingJob(true)
        d(setJobList([]))
        jobApi({ jobTitle: professionalTitle, position: location, page: "1", pageSize: "10", numPages: "1", language, countryCode })
            .then(({ data }) => d(setJobList(data)))
            .catch(e => errorHandler("err_search_jobs", e, true))
            .finally(() => setLoadingJob(false))
    }, [professionalTitle, location, countryCode, language])

    const getBookList = useCallback(async () => {
        setLoadingBook(true)
        d(setBookList([]))
        bookApi({ q: professionalTitle, page: 0, language })
            .then(({ data }) => d(setBookList(data.items)))
            .catch(e => errorHandler("err_search_books", e, true))
            .finally(() => setLoadingBook(false))

    }, [professionalTitle, language])

    // ------------------------------------------------------------------------------------------------------
    useEffect(() => {
        getNewsList();
        isCourseOn && getCourseList();
        if (isJobOn && (professionalTitle || location)) getJobList()
        if (isBookOn && professionalTitle) getBookList()
    }, [isCourseOn, isJobOn, isBookOn, professionalTitle, location, language])

    useEffect(() => {
        window.addEventListener('onGetAppVersion', onGetAppVersion)
        window.webkit?.messageHandlers["getAppVersion"]?.postMessage("");
        return () => window.removeEventListener('onGetAppVersion', onGetAppVersion)
    }, [platform])


    // ------------------------------------------------------------------------------------------------------------------------
    return (
        <div className={`min-w-72 max-w-72 fixed top-0 left-0 right-0 h-full overflow-y-auto border-r border-divider ${isWeb && "pt-16"} ${!isWeb && "bg-content1"}`}>
            <div className="flex-col">

                <Listbox
                    variant={"flat"}
                    onAction={onAction}
                    className='p-0'
                    disabledKeys={_.compact([cNav, loadingNews && "news-sk", "version"])}
                >

                    {platform !== "web" && (
                        <ListboxSection showDivider>
                            <ListboxItem
                                key={"account"}
                                startContent={(<Avatar isBordered src={avatar} />)}
                                title={!firstName && !displayName && !lastName ? t("account") : `${firstName || displayName || ""} ${lastName || ""}`}
                                description={professionalTitle || ""}
                                classNames={{ base: "m-8 w-auto", description: cNav === "account" && "text-primary-foreground" }}
                                className={cNav === "account" && `opacity-100 text-primary-foreground bg-primary`}
                            />
                        </ListboxSection>
                    )}

                    {
                        (!isPro && !isWeb) && (
                            <ListboxSection showDivider>
                                <ListboxItem
                                    key={"premium"}
                                    startContent={<WebIcon name={"workspace_premium"} className={"filled text-yellow-500"} />}
                                    title={t("passToPremium")}
                                    classNames={{ base: "m-8 w-auto", title: "font-semibold text-yellow-500" }}
                                />
                            </ListboxSection>
                        )
                    }

                    <ListboxSection showDivider className='my-8' dividerProps={{ className: "mt-8" }}>

                        <ListboxItem
                            key="cv-list"
                            title={t("yourCvs")}
                            startContent={<WebIcon name={`clinical_notes`} className={cNav === "cv-list" && "filled"} />}
                            classNames={{ base: "mx-8 w-auto" }}
                            className={cNav === "cv-list" && `opacity-100 text-primary-foreground bg-primary`}
                        />

                        {
                            isJobOn && ((professionalTitle || location) && (loadingJob || jobList.length > 0)) && (
                                loadingJob ? (
                                    <ListboxItem isReadOnly key="job-sk" aria-label={t("loading")} classNames={{ base: "mx-8 mt-1 px-0 w-auto" }} >
                                        <Skeleton className="rounded-lg">
                                            <div className="h-7 rounded-lg bg-default" />
                                        </Skeleton>
                                    </ListboxItem>
                                ) : (
                                    <ListboxItem
                                        key="job-list"
                                        title={t("jobsForYou")}
                                        startContent={<WebIcon name={`business_center`} className={cNav === "job-list" && "filled"} />}
                                        classNames={{ base: "mx-8 mt-1 w-auto" }}
                                        className={cNav === "job-list" && `opacity-100 text-primary-foreground bg-primary`}
                                    />
                                )
                            )
                        }


                        {
                            (loadingNews || newsList.length > 0) && (
                                loadingNews ? (
                                    <ListboxItem isReadOnly key="news-sk" aria-label={t("loading")} classNames={{ base: "mx-8 mt-1 px-0 w-auto" }} >
                                        <Skeleton className="rounded-lg">
                                            <div className="h-7 rounded-lg bg-default" />
                                        </Skeleton>
                                    </ListboxItem>
                                ) : (
                                    <ListboxItem
                                        key="news-list"
                                        title={t("newsForYou")}
                                        startContent={<WebIcon name={`newsmode`} className={cNav === "news-list" && "filled"} />}
                                        classNames={{ base: "mx-8 mt-1 w-auto" }}
                                        className={cNav === "news-list" && `opacity-100 text-primary-foreground bg-primary`}
                                    />
                                )
                            )
                        }

                        {
                            isCourseOn && (loadingCourse || courseList.length > 0) && (
                                loadingCourse ? (
                                    <ListboxItem isReadOnly key="course-sk" aria-label={t("loading")} classNames={{ base: "mx-8 mt-1 px-0 w-auto" }} >
                                        <Skeleton className="rounded-lg">
                                            <div className="h-7 rounded-lg bg-default" />
                                        </Skeleton>
                                    </ListboxItem>
                                ) : (
                                    <ListboxItem
                                        key="course-list"
                                        title={t("coursesForYou")}
                                        startContent={<WebIcon name={`animated_images`} className={cNav === "course-list" && "filled"} />}
                                        classNames={{ base: "mx-8 mt-1 w-auto" }}
                                        className={cNav === "course-list" && `opacity-100 text-primary-foreground bg-primary`}
                                    />
                                )
                            )
                        }

                        {
                            isBookOn && (loadingBook || bookList.length > 0) && (
                                loadingBook ? (
                                    <ListboxItem isReadOnly key="book-sk" aria-label={t("loading")} classNames={{ base: "mx-8 mt-1 px-0 w-auto" }} >
                                        <Skeleton className="rounded-lg">
                                            <div className="h-7 rounded-lg bg-default" />
                                        </Skeleton>
                                    </ListboxItem>
                                ) : (
                                    <ListboxItem
                                        key="book-list"
                                        title={t("booksForYou")}
                                        startContent={<WebIcon name={`menu_book`} className={cNav === "book-list" && "filled"} />}
                                        classNames={{ base: "mx-8 mt-1 w-auto" }}
                                        className={cNav === "book-list" && `opacity-100 text-primary-foreground bg-primary`}
                                    />
                                )
                            )
                        }

                    </ListboxSection>

                    <ListboxSection showDivider={!isWeb} className='mb-8' dividerProps={{ className: "mt-8" }}>

                        {
                            isSalaryOn && (
                                <ListboxItem
                                    key="salary"
                                    title={t("avgSalary")}
                                    classNames={{ base: "mx-8 w-auto" }}
                                    className={cNav === "salary" && `opacity-100 text-primary-foreground bg-primary`}
                                    startContent={<WebIcon name={"payments"} className={cNav === "salary" && "filled"} />}
                                />
                            )
                        }

                        {/*
                            <ListboxItem key="interview-list" startContent={<span className="material-symbols-outlined">3p</span>}>
                                {t("simulateInterview")}
                            </ListboxItem>
                            <ListboxItem key="quiz-list" startContent={<span className="material-symbols-outlined">quiz</span>}>
                                {t("assessSkills")}
                            </ListboxItem>
                        */}

                        <ListboxItem
                            key="services"
                            title={t("serviceTitle")}
                            startContent={<WebIcon name={"engineering"} className={cNav === "services" && "filled"} />}
                            classNames={{ base: `mx-8 ${isSalaryOn && "mt-1"} w-auto` }}
                            className={cNav === "services" && `opacity-100 text-primary-foreground bg-primary`}
                        />
                    </ListboxSection>

                    {
                        (platform === "macos" || platform === "visionos") && (
                            <ListboxSection showDivider className='mb-8' dividerProps={{ className: "mt-8" }}>
                                <ListboxItem
                                    key="contactUs"
                                    startContent={<WebIcon name={"mail"} />}
                                    title={t("contactUs")}
                                    description={"info@cvpop.com"}
                                    classNames={{ base: "mx-8 w-auto" }}
                                />
                            </ListboxSection>
                        )
                    }

                    {
                        (platform === "macos" || platform === "visionos") && (
                            <ListboxSection showDivider className='mb-8' dividerProps={{ className: "mt-8" }}>
                                <ListboxItem
                                    key="appLanguage"
                                    startContent={<WebIcon name={"translate"} />}
                                    endContent={<Avatar src={flags[language]} className="w-5 h-5 text-tiny" />}
                                    title={t("appLanguage")}
                                    classNames={{ base: "mx-8 w-auto" }}
                                />
                            </ListboxSection>
                        )
                    }

                    {
                        !isWeb && (
                            <ListboxSection showDivider={!isPro} className='mb-8'>
                                <ListboxItem
                                    key="terms"
                                    title={t("terms")}
                                    startContent={<WebIcon name={"contract"} />}
                                    classNames={{ base: "mx-8 w-auto" }}
                                />
                                <ListboxItem
                                    key="privacy"
                                    title={t("privacy")}
                                    startContent={<WebIcon name={"verified_user"} />}
                                    classNames={{ base: "mx-8 mt-1 w-auto" }}
                                />
                                <ListboxItem
                                    key="credits"
                                    title={t("credits")}
                                    startContent={<WebIcon name={"sdk"} className={cNav === "credits" && "filled"} />}
                                    classNames={{ base: `${appVersion ? "mx-8" : "m-8"} mt-1 w-auto` }}
                                    className={cNav === "credits" && `opacity-100 text-primary-foreground bg-primary`}
                                />
                                {
                                    appVersion && (
                                        <ListboxItem
                                            isReadOnly
                                            key="version"
                                            title={t("appVersion")}
                                            startContent={<WebIcon name={"code"} />}
                                            endContent={<span className='text-xs'>{appVersion}</span>}
                                            classNames={{ base: "m-8 mt-1 w-auto" }}
                                        />
                                    )
                                }
                            </ListboxSection>
                        )
                    }

                </Listbox>

            </div>

            <Dropdown
                isOpen={isLanguageMenuOpen}
                onOpenChange={e => setIsLanguageMenuOpen(e)}
                placement={"right"}
                shouldBlockScroll={false}
                className={`${cs} text-foreground`}
                classNames={{ base: "max-h-72 overflow-y-scroll" }}
            >
                <DropdownTrigger>
                    <div />
                </DropdownTrigger>
                <DropdownMenu aria-label="Static Actions" color={"primary"}>
                    {
                        cvPickerItems.labelLanguage().map(({ code, label }, i) => (
                            <DropdownItem
                                onClick={() => {
                                    d(setUser({ user: produce(user, d => { d.language = code }) }))
                                    setAppLocalization(code)
                                }}
                                key={code}
                                title={label}
                                startContent={<Avatar src={flags[code]} className="w-5 h-5 text-tiny" />}
                            />
                        ))
                    }
                </DropdownMenu>
            </Dropdown >

        </div>
    )
}

export default WebHomeSidebar