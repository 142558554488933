import { useCallback, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, Outlet, useParams, useLocation } from "react-router-dom";
import { Avatar, Listbox, ListboxItem, Button } from "@nextui-org/react";
import { httpsCallable } from "firebase/functions";

import { pictures } from "cvpop-assets-sdk";
import { setBookList } from "cvpop-redux-sdk";
import { t } from "cvpop-localization-sdk";

import { errorHandler } from "../../../../controllers/app";
import { functions } from "../../../../controllers/firebase";


const WebBookContainer = () => {

    // ------------------------------------------------------------------------------------------------------------------------
    const { user } = useSelector(state => state.userReducer)
    const { bookList } = useSelector(state => state.bookReducer)
    const { platform, bookId } = useParams()
    const { pathname } = useLocation()

    // ------------------------------------------------------------------------------------------------------
    const { language } = user

    // ------------------------------------------------------------------------------------------------------------------------
    const d = useDispatch()
    const n = useNavigate()

    // ------------------------------------------------------------------------------------------------------------------------
    const bookApi = useMemo(() => httpsCallable(functions, "getbooklist"), [functions]);
    const isWeb = useMemo(() => platform === "web", [platform])

    // ------------------------------------------------------------------------------------------------------------------------
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(11) // start always from 2
    const [more, setMore] = useState(true)

    // ------------------------------------------------------------------------------------------------------
    const getBookList = useCallback(() => {
        setLoading(true)
        bookApi({ q: user.professionalTitle, page, language })
            .then(({ data }) => {
                if (data.items.length < 10) return setMore(false)
                d(setBookList([...bookList, ...data.items]))
                setPage(page + 10)
            })
            .catch(e => errorHandler("err_search_books", e, true))
            .finally(() => setLoading(false))
    }, [user.professionalTitle, bookList, language, page])

    // ------------------------------------------------------------------------------------------------------------------------ 
    return (
        <div className="flex" style={{ height: isWeb ? `calc(100vh - 4rem)` : "100vh" }}>

            <div className="w-4/12  overflow-y-auto bg-content1 p-1 pb-8 border-r border-divider" style={{ height: isWeb ? `calc(100vh - 4rem)` : "100vh" }}>

                <Listbox
                    variant={"flat"}
                    onAction={k => n(`${k}`)}
                    disabledKeys={[bookId]}
                    classNames={{ base: "pb-0" }}
                    bottomContent={more && (
                        <Button color={"primary"} className="mt-4" isLoading={loading} onClick={() => getBookList()}>
                            {t("showMore")}
                        </Button>
                    )}
                >
                    {
                        bookList.map(({ id, volumeInfo }, i) => (
                            <ListboxItem
                                key={id}
                                startContent={(
                                    <Avatar
                                        radius="none"
                                        src={volumeInfo.imageLinks && (volumeInfo.imageLinks.thumbnail || volumeInfo.imageLinks.smallThumbnail)}
                                        fallback={pictures.genericPlaceholder}
                                        name={volumeInfo.title} />
                                )}
                                title={volumeInfo.title}
                                description={volumeInfo.subtitle || (volumeInfo.authors && volumeInfo.authors.length > 0 ? volumeInfo.authors.join(", ") : "")}
                                className={bookId === String(id) && `opacity-100 text-primary-foreground bg-primary`}
                                classNames={{ title: "whitespace-normal font-semibold", description: bookId === String(id) && "text-primary-50" }}
                                showDivider={i < bookList.length - 1}
                            />
                        ))
                    }
                </Listbox>

            </div>

            {
                ([`/app/${platform}/home/book-list`, `/app/${platform}/home/book-list/`].includes(pathname)) ? (
                    <div className="w-8/12 bg-content1 flex items-center justify-center" style={{ height: isWeb ? `calc(100vh - 4rem)` : "100vh" }}>
                        <p className="text-3xl text-center text-default p-8">{t("noItemsSelected")}</p>
                    </div>
                ) : (
                    <Outlet />
                )
            }
        </div>
    )
}

export default WebBookContainer