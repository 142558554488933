import { useCallback, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, Outlet, useParams, useLocation } from "react-router-dom";
import { Avatar, Listbox, ListboxItem, Button } from "@nextui-org/react";
import { collection, where, query, orderBy, limit, getDocs, startAfter } from "firebase/firestore";

import { setNewsList } from "cvpop-redux-sdk";
import { t } from "cvpop-localization-sdk";

import _ from "lodash"

import { db } from "../../../../controllers/firebase";
import { errorHandler } from "../../../../controllers/app";


const WebNewsContainer = () => {

    // ------------------------------------------------------------------------------------------------------------------------
    const { newsList } = useSelector(state => state.newsReducer)
    const { user } = useSelector(state => state.userReducer)
    const { platform, newsId } = useParams()
    const { pathname } = useLocation()

    // ------------------------------------------------------------------------------------------------------
    const { language } = user

    // ------------------------------------------------------------------------------------------------------------------------
    const d = useDispatch()
    const n = useNavigate()

    // ------------------------------------------------------------------------------------------------------------------------
    const blogLanguage = useMemo(() => ["it", "en", "es"].includes(language) ? language : "en", [language])
    const isWeb = useMemo(() => platform === "web", [platform])

    // ------------------------------------------------------------------------------------------------------------------------
    const [loading, setLoading] = useState(false);

    // ------------------------------------------------------------------------------------------------------
    const getNews = useCallback(() => {
        setLoading(true)
        const ref = collection(db, "00_POSTS_00")
        getDocs(query(ref, where("blog.language", "==", blogLanguage), orderBy("postedAt", "desc"), limit(10), startAfter(_.last(newsList).postedAt)))
            .then(s => d(setNewsList([...newsList, ...s.docs.map(e => e.data())])))
            .catch(e => errorHandler("err_get_news", e, true))
            .finally(() => setLoading(false))
    }, [newsList])

    // ------------------------------------------------------------------------------------------------------------------------ 
    return (
        <div className="flex" style={{ height: isWeb ? `calc(100vh - 4rem)` : "100vh" }}>

            <div className="w-4/12  overflow-y-auto bg-content1 p-1 pb-8 border-r border-divider" style={{ height: isWeb ? `calc(100vh - 4rem)` : "100vh" }}>

                <Listbox
                    variant={"flat"}
                    onAction={k => n(k)}
                    disabledKeys={[newsId]}
                    classNames={{ base: "pb-0" }}
                    bottomContent={(
                        <Button color={"primary"} className="mt-4" isLoading={loading} onClick={() => getNews()}>
                            {t("showMore")}
                        </Button>
                    )}
                >
                    {
                        newsList.map(({ id, title, image, description, creator, blog }, i) => (
                            <ListboxItem
                                key={id}
                                startContent={(<Avatar radius="sm" src={image || blog.favicon} name={blog.name} />)}
                                title={title}
                                description={description || `${blog.name} - ${creator})`}
                                className={newsId === id && `opacity-100 text-primary-foreground bg-primary`}
                                classNames={{ title: "whitespace-normal font-semibold", description: newsId === id && "text-primary-50" }}
                                showDivider={i < newsList.length - 1}
                            />
                        ))
                    }
                </Listbox>

            </div>

            {
                ([`/app/${platform}/home/news-list`, `/app/${platform}/home/news-list/`].includes(pathname)) ? (
                    <div className="w-8/12 bg-content1 flex items-center justify-center" style={{ height: isWeb ? `calc(100vh - 4rem)` : "100vh" }}>
                        <p className="text-3xl text-center text-default p-8">{t("noItemsSelected")}</p>
                    </div>
                ) : (
                    <Outlet />
                )
            }
        </div>
    )
}

export default WebNewsContainer