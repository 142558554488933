module.exports = {

    onboardingSeniorityCatalogue: ["Entry", "Intermediate", "Senior", "Managerial", "Director", "Executive"],

    onboardingGoalCatalogue: [
        "JobApplication",
        "Networking",
        "PromotionOrTransfer",
        "Freelancing",
        "PersonalBranding",
        "CareerTransition",
        "Other"
    ],

    onboardingTimeCatalogue: ["Now", "2Week", "1Month", "3Month", "More"],

    onboardingPersonalityCatalogue: [
        "Proactive", 
        "Creative", 
        "Dynamic", 
        "Analytical", 
        "Communicative", 
        "Empathetic", 
        "Efficient", 
        "Organized", 
        "Resilient", 
        "Perceptive", 
        "Minimalist", 
        "Accurate"
    ],

    onboardingColorCatalogue: [
        "#1E90FF", "#4682B4", "#00008B", "#2F4F4F",
        "#191970", "#79BE79", "#228B22", "#20B2AA",
        "#385859", "#556B2F", "#8B4513", "#8B008B",
        "#B22222", "#CD5C5C", "#F4A460", "#FF8C00"
    ],

}