import { useCallback, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Autocomplete, AutocompleteItem } from "@nextui-org/react";

import { t } from 'cvpop-localization-sdk';
import { httpsCallable } from 'firebase/functions';
import { logos } from 'cvpop-assets-sdk';

import _ from "lodash"

import { functions } from '../../../controllers/firebase';
import { errorHandler } from '../../../controllers/app';
import { useColorScheme } from '../../../controllers/hooks';


const WebLocationAutocomplete = (props) => {

    // ------------------------------------------------------------------------------------------------------
    const { user } = useSelector(state => state.userReducer)

    // ------------------------------------------------------------------------------------------------------
    const { language } = user

    // ------------------------------------------------------------------------------------------------------
    const cs = useColorScheme()

    // ------------------------------------------------------------------------------------------------------
    const api = useMemo(() => httpsCallable(functions, "getlocationautocomplete"), [])

    // ------------------------------------------------------------------------------------------------------
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)

    // ------------------------------------------------------------------------------------------------------
    const searchPlace = useCallback(_.debounce(q => {
        if (!q || q.length < 3) return
        setLoading(true)
        api({ q, language })
            .then(({ data }) => setData(data.predictions))
            .catch(e => errorHandler("err_place_autocomplete", e))
            .finally(() => setLoading(false))
    }, 1000), [language])

    // ------------------------------------------------------------------------------------------------------
    return (
        <Autocomplete
            defaultItems={data}
            placeholder={t("locationPh")}
            className='mt-3'
            size={"lg"}
            isLoading={loading}
            onInputChange={q => searchPlace(q)}
            listboxProps={{
                bottomContent: data.length > 0 && (
                    <div className='flex items-center m-2 text-xs'>
                        <p>Powered by</p>
                        <img src={logos.google[cs]} className='w-12 object-contain ml-1' />
                    </div>
                )
            }}
            {...props}
        >
            {({ description }) => <AutocompleteItem key={description}>{description}</AutocompleteItem>}
        </Autocomplete>
    )

}

export default WebLocationAutocomplete