import React, { useState, useRef, useMemo } from 'react';
import { Card, CardBody, Button, Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, useDisclosure } from "@nextui-org/react"
import { useNavigate, useParams } from 'react-router-dom';
import { Cropper } from 'react-advanced-cropper';
import { useDispatch, useSelector } from 'react-redux';
import { produce } from "immer"

import { setUser } from 'cvpop-redux-sdk'
import { illustrations } from "cvpop-assets-sdk"
import { t } from "cvpop-localization-sdk";
import { getAvatarUser } from 'cvpop-utility-sdk';

import WebIcon from '../../atoms/WebIcon';

import { errorHandler, imageToBase64, showAlert } from '../../../controllers/app';
import { useColorScheme } from '../../../controllers/hooks';

import 'react-advanced-cropper/dist/style.css'


const WebIntroAvatar = () => {

    // ------------------------------------------------------------------------------------------------------
    const { user } = useSelector(state => state.userReducer)
    const { isOpen, onOpen, onClose, onOpenChange } = useDisclosure();
    const { platform } = useParams()

    // ------------------------------------------------------------------------------------------------------
    const d = useDispatch()
    const n = useNavigate()
    const cs = useColorScheme()
    const inputRef = useRef()
    const cropRef = useRef()

    // ------------------------------------------------------------------------------------------------------
    const avatar = useMemo(() => getAvatarUser(user, platform) || illustrations.avatar, [user, platform])

    // ------------------------------------------------------------------------------------------------------
    const [loading, setLoading] = useState("")
    const [cropImg, setCropImg] = useState("")

    // ------------------------------------------------------------------------------------------------------
    return (
        <div className='flex justify-center items-center flex-col' style={{ minHeight: `calc(100vh - ${platform === "web" ? "4rem" : "0px"})` }}>
            <Card className='max-w-md m-3'>
                {
                    platform !== "web" && (
                        <Button isIconOnly variant={"light"} className='m-3 mb-0' onClick={() => n(-1)}>
                            <WebIcon name={"west"} />
                        </Button>
                    )
                }

                <CardBody>
                    <div className="max-w-80 self-center">
                        <img alt={t("introAvatarTitle")} src={avatar} className={`rounded-xl`} />
                    </div>
                    <p className="font-sans font-bold text-2xl text-center mt-3">{t("introAvatarTitle")}</p>
                    <p className="font-sans text-center mt-3">{t("photoMsg")}</p>

                    <Button
                        color="primary"
                        className='mt-3'
                        isLoading={loading}
                        onClick={() => {
                            if (!inputRef.current) return
                            inputRef.current.value = ""
                            inputRef.current.click()
                        }}
                    >
                        {t("choosePhoto")}
                    </Button>

                    <Button className='mt-3' color='primary' variant={"light"} onClick={() => n(`/app/${platform}/onboarding/email`)}>
                        {t("skip")}
                    </Button>

                    <input
                        type="file"
                        className='hidden'
                        accept="image/png,image/jpeg"
                        ref={inputRef}
                        onChange={({ target }) => {
                            if (target.files.length === 0) return
                            if (!["image/png", "image/jpeg"].includes(target.files[0].type)) return showAlert(t("errTitle"), t("notSupportedTypeFile"))
                            setLoading(true)
                            imageToBase64(target.files[0])
                                .then(b64 => {
                                    setCropImg(b64);
                                    onOpen();
                                })
                                .catch(e => errorHandler("err_convert_base64", e))
                                .finally(() => setLoading(false))
                        }}
                    />

                </CardBody>
            </Card>

            <Modal
                isOpen={isOpen}
                onOpenChange={onOpenChange}
                hideCloseButton={true}
                backdrop={"blur"}
                className={`${cs} text-foreground`}
            >
                <ModalContent>
                    <ModalHeader className="flex flex-col gap-1">{t("cutImage")}</ModalHeader>

                    <ModalBody>
                        <Cropper ref={cropRef} src={cropImg} aspectRatio={0.75} />
                    </ModalBody>

                    <ModalFooter>
                        <Button color="danger" variant="light" onPress={onClose} >
                            {t("cancel")}
                        </Button>
                        <Button
                            color="primary"
                            onPress={() => {
                                if (!cropRef.current) return
                                const croppedImage = cropRef.current.getCanvas().toDataURL("image/jpeg");
                                d(setUser({ user: produce(user, d => { d.avatar = croppedImage.split(',')[1] }) }));
                                onClose()
                                n(`/app/${platform}/onboarding/email`)
                            }}
                        >
                            {t("done")}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

        </div>
    )
}

export default WebIntroAvatar