import { useMemo, useCallback } from "react"
import { useSelector } from "react-redux"
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Listbox, ListboxItem, ListboxSection, Button } from "@nextui-org/react";
import { getBoolean } from "firebase/remote-config";

import { isProUser } from "cvpop-utility-sdk"
import { t } from "cvpop-localization-sdk"

import { goToPaywall } from "../../../controllers/app";
import { remoteConfig } from "../../../controllers/firebase";

import WebTranslateCVButton from "../../molecules/buttons/WebTranslateCVButton";
import WebDuplicateCVButton from "../../molecules/buttons/WebDuplicateCVButton";
import WebDownloadCVButton from "../../molecules/buttons/WebDownloadCVButton";
import WebIcon from "../../atoms/WebIcon";

const WebCVSidebar = () => {

    // ------------------------------------------------------------------------------------------------------------------------
    const { user } = useSelector(state => state.userReducer)
    const { offering } = useSelector(state => state.appReducer)
    const { platform } = useParams()
    const { pathname } = useLocation()

    // ------------------------------------------------------------------------------------------------------------------------
    const n = useNavigate()

    // ------------------------------------------------------------------------------------------------------------------------
    const isWeb = useMemo(() => platform === "web", [platform])
    const isPro = useMemo(() => isProUser(user), [user])
    const cNav = useMemo(() => (pathname || "").split('/')[4], [pathname])
    const scOn = useMemo(() => (getBoolean(remoteConfig, "openAIOn")), [])

    // ------------------------------------------------------------------------------------------------------------------------
    const { canGoForward } = window?.navigation || {}

    // ------------------------------------------------------------------------------------------------------------------------
    const onAction = useCallback(k => {

        if (k === "translate" || k === "duplicate" || k === "download") return null
        if (k === "home") return n(`/app/${platform}/home/cv-list`)
        if (k === "premium") return goToPaywall(offering, platform, n)
        n(k)

    }, [offering, platform, n])

    // ------------------------------------------------------------------------------------------------------------------------
    return (
        <div className={`min-w-32 max-w-32 fixed top-0 left-0 right-0 h-full overflow-y-auto border-r border-divider ${isWeb && "pt-16"} ${!isWeb && "bg-content1"}`}>

            {
                !isWeb && (
                    <div className="flex py-8 items-center justify-center border-b border-divider" style={{ height: 96 }}>
                        <Button isIconOnly className="mr-2" onClick={() => n(-1)} variant={"light"}>
                            <WebIcon name={"west"} />
                        </Button>
                        <Button isIconOnly isDisabled={!canGoForward} onClick={() => n(+1)} variant={"light"}>
                            <WebIcon name={"east"} />
                        </Button>
                    </div>
                )
            }



            <div className="flex">

                <Listbox
                    aria-label="Actions"
                    variant={"flat"}
                    disabledKeys={[cNav]}
                    className="py-4"
                    onAction={onAction}
                >

                    {
                        (!isPro && !isWeb) && (
                            <ListboxSection showDivider>
                                <ListboxItem key="premium" textValue={t("passToPremium")} classNames={{ base: "my-4 mt-0", title: "font-semibold text-yellow-500" }}>
                                    <div className="flex flex-col items-center">
                                        <WebIcon name={"workspace_premium"} className={"filled text-yellow-500"} />
                                        <span className="text-xs text-yellow-500">{t("passToPremium")}</span>
                                    </div>
                                </ListboxItem>
                            </ListboxSection>
                        )
                    }

                    <ListboxSection showDivider={!isWeb}>

                        <ListboxItem key="edit" textValue={t("edit")} classNames={{ base: "mx-4 w-auto" }} className={cNav === "edit" && `opacity-100 text-primary-foreground bg-primary`}>
                            <div className={`flex flex-col items-center`}>
                                <WebIcon name={"edit_square"} className={cNav === "edit" && `filled`} />
                                <span className={`text-xs`}>{t("edit")}</span>
                            </div>
                        </ListboxItem>

                        <ListboxItem key="style" textValue={t("style")} classNames={{ base: "mx-4 my-1 w-auto" }} className={cNav === "style" && `opacity-100 text-primary-foreground bg-primary`}>
                            <div className={`flex flex-col items-center`}>
                                <WebIcon name={"palette"} className={cNav === "style" && `filled`} />
                                <span className={`text-xs `}>{t("style")}</span>
                            </div>
                        </ListboxItem>

                        <ListboxItem key="download" textValue={t("download")} classNames={{ base: "mx-4 my-1 w-auto" }} >
                            <WebDownloadCVButton />
                        </ListboxItem>

                        {scOn && (
                            <ListboxItem key="scan" textValue={t("scan")} classNames={{ base: "mx-4 my-1 w-auto" }} className={cNav === "scan" && `opacity-100 text-primary-foreground bg-primary`} >
                                <div className={`flex flex-col items-center`}>
                                    <WebIcon name={"scan"} className={cNav === "scan" && `filled`} />
                                    <span className={`text-xs `}>{t("scan")}</span>
                                </div>
                            </ListboxItem>
                        )}

                        <ListboxItem key="translate" textValue={t("translate")} classNames={{ base: "mx-4 my-1 w-auto" }}>
                            <WebTranslateCVButton />
                        </ListboxItem>

                        <ListboxItem key="duplicate" textValue={t("duplicate")} classNames={{ base: "mx-4 my-1 w-auto" }}>
                            <WebDuplicateCVButton />
                        </ListboxItem>

                        <ListboxItem key="public-link" textValue={t("publicLink")} classNames={{ base: "mx-4 mt-1 w-auto" }} className={cNav === "public-link" && `opacity-100 text-primary-foreground bg-primary`}>
                            <div className={`flex flex-col items-center`}>
                                <WebIcon name={"link"} className={cNav === "public-link" && `filled`} />
                                <span className={`text-xs`}>{t("link")}</span>
                            </div>
                        </ListboxItem>

                        <ListboxItem key="settings" textValue={t("settings")} classNames={{ base: "mx-4 mb-4 mt-1 w-auto" }} className={cNav === "settings" && `opacity-100 text-primary-foreground bg-primary`}>
                            <div className={`flex flex-col items-center`}>
                                <WebIcon name={"settings"} className={cNav === "settings" && `filled`} />
                                <span className={`text-xs`}>{t("settings")}</span>
                            </div>
                        </ListboxItem>

                    </ListboxSection>


                    {
                        !isWeb && (
                            <ListboxSection>
                                <ListboxItem key="home" textValue={"Home"} classNames={{ base: "m-4 mt-1 w-auto" }}>
                                    <div className="flex flex-col items-center">
                                        <span className="material-symbols-outlined">home</span>
                                        <span className="text-xs">Home</span>
                                    </div>
                                </ListboxItem>
                            </ListboxSection>
                        )
                    }

                </Listbox>

            </div>
        </div>
    )

}

export default WebCVSidebar