const { PDFDocument, PDFRawStream, PDFName, PDFHexString } = require('pdf-lib')

const { t } = require("cvpop-localization-sdk")
const { languages } = require("cvpop-collections-sdk")
// const { icons, languages } = require("cvpop-assets-sdk")

const { defaultCvObject, cvPickerItems, modelCatalogue, colors } = require("cvpop-constants-sdk")

const _ = require("lodash")
const moment = require("moment/min/moment-with-locales.min.js")

const { removeKeysFromObj, dateToString, getTextFromHtml } = require("./script")
const { userHasCap } = require('./user')

// GLOBAL FUNCTIONS ------------------------------------------------------------------------------------------------------
const stringToCvDate = str => {

    let momentDate = moment(str, "YYYY-MM-DD");

    // try other formats..
    if (!momentDate.isValid()) momentDate = moment(str, "YYYY-MM");
    if (!momentDate.isValid()) momentDate = moment(str, "YYYY");
    if (!momentDate.isValid()) momentDate = moment();

    const date = momentDate.toDate()

    return { Year: date.getFullYear(), Month: date.getMonth() + 1, Day: date.getDate() }
}

const importDataToJson = (dt, user, createFbIdFnc) => {

    const { info = {}, skills = {}, works = [], educations = [], abilities = [], keywords = [], courses = [], certifications = [] } = dt
    const { name = "", surname = "", phone = "", website = "", jobTitle = "", email = "", dateOfBirth = "", address = "", language = "" } = info || {};
    const { communication = "", organisational = "", jobRelated = "", computer = "", personal = "" } = skills || {};

    let Achievement = [];
    certifications instanceof Array && !_.isEmpty(certifications) && Achievement.push({
        Id: createFbIdFnc(),
        Title: { Code: "certifications", Label: "Certifications" },
        Description: "<ul>" + certifications.map(e => `<li>${e.name}${e.organisation ? " (<i>" + e.organisation + "</i>)" : ""}</li>`).join("") + "</ul>"
    })

    courses instanceof Array && !_.isEmpty(courses) && Achievement.push({
        Id: createFbIdFnc(),
        Title: { Code: "courses", Label: "Courses" },
        Description: "<ul>" + courses.map(e => `<li>${e}</li>`).join("") + "</ul>"
    })

    return {
        Metadata: {
            modelType: "london",
            lng: cvPickerItems.labelLanguage().find(o => o.code === ((language || user.language) || "").toLowerCase())?.code || "en",
            isImportedAutomatically: true
        },
        SkillsPassport: {
            LearnerInfo: {
                Identification: {
                    PersonName: { FirstName: name || "", Surname: surname || "" },
                    ProfessionalTitle: jobTitle || "",
                    Demographics: { Birthdate: dateOfBirth ? stringToCvDate(dateOfBirth) : { Year: null, Month: null, Day: null } },
                    ContactInfo: {
                        Address: { Contact: { AddressLine: address || "", PostalCode: "", Municipality: "" } },
                        Email: { Contact: email || "" },
                        Telephone: phone ? [{ Id: createFbIdFnc(), Contact: phone, Use: { Code: "", Label: "" } }] : [],
                        Website: website ? [{ Id: createFbIdFnc(), Contact: website, Use: { Code: "", Label: "" } }] : [],
                    },
                },
                WorkExperience: !(works instanceof Array) || _.isEmpty(works) ? [] : (works || []).map(e => ({
                    Id: createFbIdFnc(),
                    Activities: e.responsability || "",
                    Position: { Label: e.position || "" },
                    Employer: { Name: e.company || "", ContactInfo: { Address: { Contact: { Municipality: e.companyAddress || "" } } }, Sector: { Label: e.sector || "" } },
                    Period: { Current: e.inProgress || false, From: stringToCvDate(e.fromDate), To: stringToCvDate(e.endDate) }
                })),
                Education: !(educations instanceof Array) || _.isEmpty(educations) ? [] : (educations || []).map(e => ({
                    Id: createFbIdFnc(),
                    Activities: e.responsability || "",
                    Title: e.qualification || "",
                    Organisation: { Name: e.institute || "", ContactInfo: { Address: { Contact: { Municipality: e.instituteAddress || "" } } } },
                    Period: { Current: e.inProgress || false, From: stringToCvDate(e.fromDate), To: stringToCvDate(e.endDate) }
                })),
                ExpertiseArea: (keywords instanceof Array ? keywords : []).map(e => ({ Id: createFbIdFnc(), Title: e || "" })),
                Ability: (abilities instanceof Array ? abilities : []).map(e => ({ Id: createFbIdFnc(), Title: e || "", Level: _.random(2, 5) })),
                Skills: {
                    Other: { Description: personal instanceof String ? personal : "" },
                    Communication: { Description: communication instanceof String ? communication : "" },
                    Organisational: { Description: organisational instanceof String ? organisational : "" },
                    JobRelated: { Description: jobRelated instanceof String ? jobRelated : "" },
                    Computer: { Description: computer instanceof String ? computer : "" },
                },
                Achievement,
            },
        }
    }
}

const getCvPickerItemLabel = (key, code) => cvPickerItems[key]().find(o => o.code === code)?.label || "";


// ------------------------------------------------------------------------------------------------------
module.exports = {
    createCvObject: (fbuser = {}, user = {}, id = "", content = {}, type = "", extraDt = {}) => {

        const { uid, displayName, email } = fbuser
        const { language, avatar, firstName, lastName, location, professionalTitle, introChoices, phone, linkedinId } = user

        const createId = () => (Math.random() + 1).toString(36).substring(2);
        const time = new Date().getTime();
        const personality = (introChoices || {}).personality || ""
        const cvLayout = (introChoices || {}).cvLayout || ""
        const photo = avatar ?
            { "MimeType": "image/jpeg", "Data": avatar, "Metadata": [{ "Key": "dimension", "Value": "300x400" }] } :
            { "MimeType": "", "Data": "", "Metadata": [] }

        let newCv = _.cloneDeep(defaultCvObject)

        // adding metadata
        newCv.Metadata = {
            id: id,
            name: `CV ${firstName || displayName || ""} ${lastName || ""}`.trim(),
            uid: uid,
            createdAt: time,
            updatedAt: time,
            modelBaseColor: introChoices?.color || colors.primary,
            modelType: ["Creative", "Communicative", "Dynamic", "Proactive"].includes(personality) ? "newyork" : ["Empathetic", "Analytical", "Efficient"].includes(personality) ? "rio" : "london",
            lng: cvPickerItems.labelLanguage().find(o => o.code === language) ? language : "en",
        }

        if (cvLayout) {
            const filteredModelCatalogue = modelCatalogue.filter(e => e.category === cvLayout);
            if (filteredModelCatalogue.length > 0) newCv.Metadata.modelType = filteredModelCatalogue[Math.floor(Math.random() * filteredModelCatalogue.length)].code
        }

        if (["createdByImport", "createdByLinkedin"].includes(type)) {
            if (type === "createdByImport") {
                newCv.Metadata.isImported = true;
                newCv.Metadata.imporedTime = time;
            }
            if (type === "createdByLinkedin") {
                newCv.Metadata.name = "CV Linkedin"
                newCv.Metadata.isFromLinkedin = true;
            }
        } else {

            newCv.SkillsPassport.LearnerInfo.Identification.PersonName.FirstName = firstName || displayName || "";
            newCv.SkillsPassport.LearnerInfo.Identification.PersonName.Surname = lastName || "";
            newCv.SkillsPassport.LearnerInfo.Identification.ProfessionalTitle = professionalTitle || "";
            newCv.SkillsPassport.LearnerInfo.Identification.ContactInfo.Email.Contact = user.email || email || "";
            newCv.SkillsPassport.LearnerInfo.Identification.ContactInfo.Address.Contact.Municipality = location || ""
            newCv.SkillsPassport.LearnerInfo.Identification.Photo = photo
            if (phone)
                newCv.SkillsPassport.LearnerInfo.Identification.ContactInfo.Telephone = [{ Id: createId(), Contact: phone, Use: { Code: "mobile", Label: "phone" } }]
            if (linkedinId)
                newCv.SkillsPassport.LearnerInfo.Identification.ContactInfo.SocialAccount = [{ Id: createId(), Contact: `https://www.linkedin.com/in/${linkedinId}`, Use: { Code: "linkedin", Label: "linkedin" } }]

            if (type === "createdByJobTitle")
                newCv.SkillsPassport.LearnerInfo.Identification.ProfessionalTitle = extraDt.jobTitle || "";

            if (type === "createdByJob") {
                newCv.Metadata.isFromJob = true;
                newCv.Metadata.jobUrl = extraDt.url;
                newCv.Metadata.name = extraDt.title;
            }
        }

        if (content) newCv = _.cloneDeep(_.merge(newCv, content));

        return newCv

    },

    duplicateCvObject: (cv = {}, id = "", appendix = "") => {

        let newCv = _.cloneDeep(cv)

        const time = new Date().getTime();

        newCv.Metadata.createdAt = time;
        newCv.Metadata.updatedAt = time;
        newCv.Metadata.id = id;
        newCv.Metadata.name = `${cv.Metadata.name} (${appendix})`;
        newCv.Metadata.isDuplicated = true;

        delete newCv.Metadata.publicLinkId;
        delete newCv.SkillsPassport.LearnerInfo.Video;
        delete newCv.SkillsPassport.LearnerInfo.Attachment;

        return newCv

    },

    // ------------------------------------------------------------------------------------------------------
    getLabelFromCvSection: k => {
        switch (k) {
            case 'driver-licences-special': return "cvLabelSpecialDriverLicence"
            case 'summary': return "cvLabelSummary"
            case 'portfolio': return "cvLabelPortfolio"
            case 'expertise-areas': return "cvLabelKeyword"
            case 'abilities': return "cvLabelAbility"
            case 'work': return "cvLabelExperience"
            case 'skills': return "cvLabelSkills"
            case 'achievements': return "cvLabelAchievement"
            case 'contact-info': return "cvLabelPersonalInfo"
            case 'languages': return "cvLabelMotherTongue"
            case 'foreign-languages': return "cvLabelForeignLanguage"
            case 'driver-licences': return "cvLabelDriverLicence"
            case 'education': return "cvLabelEducation"
            case 'headline': return "cvLabelHeadline"
            case 'video': return "cvLabelVideo"
            case 'attachments': return "cvLabelAttachment"
            default: return k
        }
    },

    getCvSectionOrder: cv => {

        // this functions is to introduce other sections on CV that a reorder without these new sections
        let object = _.cloneDeep(cv.Metadata.modelArrange)

        if (!object) return false;
        const objKeys = Object.keys(object);

        // This is the list of new sections
        const eToCheck = ['driver-licences-special', 'abilities', 'expertise-areas', 'summary', 'video', 'portfolio', 'attachments'];
        for (let e in eToCheck) {
            let isPresent = false;
            objKeys.map((key) => { if (object[key].includes(eToCheck[e])) isPresent = true; })
            if (isPresent) continue;
            object[objKeys[0]].unshift(eToCheck[e]);
        }
        return object
    },

    getCvPickerItemLabel: getCvPickerItemLabel,


    getCvImage: (cv, platform) => {
        const photo = cv.SkillsPassport.LearnerInfo.Identification.Photo;
        if (!(photo && photo.Data)) return null
        const uri = `data:image/png;base64,${photo.Data}`
        return ["ios", "android"].includes(platform) ? { uri } : uri
    },

    getCvInitials: cv => {

        const { FirstName, Surname } = cv.SkillsPassport.LearnerInfo.Identification.PersonName
        const initials = `${FirstName} ${Surname}`.match(/(\b\S)?/g).join("").toUpperCase();
        return initials || "CV"

    },

    getCvReadyToTranslate: cv => removeKeysFromObj(cv, [
        'Metadata', 'DocumentInfo', 'Driving', 'Linguistic',
        'PersonName', 'Photo', 'Demographics',
        'Website', 'Email', 'Telephone', 'InstantMessaging',
        'AddressLine', 'PostalCode', 'Country',
        'Type', 'Period', 'Code', 'Id',
        'Attachment', 'ReferenceTo', 'Signature', 'DriverLicenceSpecial', 'Level',
        'Video', 'Logo', "Portfolio"
    ]),


    getCvSectionTextObject: (cv, sectionId, language, item) => {

        if (["SIGNATURE", "ATTACHMENT", "VIDEO", "PORTFOLIO"].includes(sectionId)) return null

        const { Skills, Identification, Headline, Ability = [], ExpertiseArea = [], Achievement = [], Education = [], WorkExperience = [] } = cv.SkillsPassport.LearnerInfo

        const { PersonName, Demographics, ContactInfo, ProfessionalTitle = "", Photo, Summary, CoverLetter, Signature } = Identification
        const { Address, Email, Telephone = [], Website = [], SocialAccount = [], InstantMessaging = [] } = ContactInfo
        const { AddressLine = "", PostalCode = "", Municipality = "", Country } = Address.Contact
        const { FirstName = "", Surname = "" } = PersonName
        const { Birthdate, Nationality, Gender } = Demographics

        const { Communication, Organisational, JobRelated, Other, Computer, Linguistic } = Skills
        const { ForeignLanguage } = Linguistic

        const getWorkItem = e => ({
            [t('period')]: {
                [t('from')]: dateToString(cv, e.Period.From, "WORK", language),
                [t('to')]: e.Period.Current ? t('cvLabelInProgress') : dateToString(cv, e.Period.To, "WORK", language),
            },
            [t("employer")]: {
                [t('companyName')]: e.Employer.Name || "",
                [t('city')]: e.Employer.ContactInfo.Address.Contact.Municipality,
                [t('logo')]: e.Employer.Logo?.Data ? t("provided") : t("notSpecified")
            },
            [t("qualification")]: {
                [t('positionHeld')]: e.Position.Label || "",
                [t('cvLabelWorkSector')]: e.Employer.Sector?.Label || "",
            },
            [t('description')]: getTextFromHtml(e.Activities)
        })

        const getEducationItem = e => ({
            [t('period')]: {
                [t('from')]: dateToString(cv, e.Period.From, "EDUCATION", language),
                [t('to')]: e.Period.Current ? t('cvLabelInProgress') : dateToString(cv, e.Period.To, "EDUCATION", language),
            },
            [t("trainingInstitution")]: {
                [t('name')]: e.Organisation.Name || "",
                [t('city')]: e.Organisation.ContactInfo.Address.Contact.Municipality,
                [t('logo')]: e.Organisation.Logo?.Data ? t("provided") : t("notSpecified")
            },
            [t("qualification")]: { [t('qualificationObtained')]: e.Title || "" },
            [t('description')]: getTextFromHtml(e.Activities)
        })

        const getAchievementItem = e => ({
            [t('title')]: e.Title.Label || "",
            [t('description')]: getTextFromHtml(e.Description || ""),
        })

        let data = {}
        if (sectionId === "PERSONAL_DATA")
            data = {
                [t("baseInfo")]: { [t("name")]: FirstName, [t("surname")]: Surname, [t("email")]: Email.Contact || "", [t("jobTitle")]: ProfessionalTitle || "" },
                [t("address").toLowerCase()]: { [t("street")]: AddressLine, [t("postalCode")]: PostalCode, [t("city")]: Municipality, [t("country")]: Country?.Label || "" },
                [t("otherFields").toLowerCase()]: {
                    [t("gender")]: t(getCvPickerItemLabel("gender", Gender.Code)),
                    [t("birthDate")]: Birthdate.Year > 0 ? dateToString(cv, Birthdate, "BIRTHDATE", language) : "",
                    [t("nationality")]: Nationality.length > 0 ? Nationality[0].Label || "" : ""
                }
            }
        if (sectionId === "PHOTO") data = Photo?.Data || null
        if (sectionId === "CONTACTS")
            data = {
                [t('phones')]: Telephone.map(e => ({ [t('type')]: t(getCvPickerItemLabel(`contactInfoTelephone`, e.Use.Code)), [t('phone')]: e.Contact })),
                [t('websites')]: Website.map(e => ({ [t('type')]: t(getCvPickerItemLabel(`contactInfoWebsite`, e.Use.Code)), [t('website')]: e.Contact })),
                [t('socialAccount')]: SocialAccount.map(e => ({ [t('type')]: t(getCvPickerItemLabel(`contactInfoSocialAccount`, e.Use.Code)), [t('socialAccount')]: e.Contact })),
                [t('instMessages')]: InstantMessaging.map(e => ({ [t('type')]: t(getCvPickerItemLabel(`contactInfoInstantMessaging`, e.Use.Code)), [t('instMessages')]: e.Contact }))
            }

        if (sectionId === "SUMMARY") data = { [t('cvLabelSummary')]: getTextFromHtml(Summary?.Description || "") }
        if (sectionId === "COVER_LETTER") data = { [t('coverLetter')]: getTextFromHtml(CoverLetter?.Description || "") }
        if (sectionId === "SKILL_COM") data = { [t('cvLabelSkillsCommunication')]: getTextFromHtml(Communication?.Description || "") }
        if (sectionId === "SKILL_ORG") data = { [t('cvLabelSkillsOrganisational')]: getTextFromHtml(Organisational?.Description || "") }
        if (sectionId === "SKILL_PRO") data = { [t('cvLabelSkillsJobRelated')]: getTextFromHtml(JobRelated?.Description || "") }
        if (sectionId === "SKILL_OTH") data = { [t('cvLabelSkillsOther')]: getTextFromHtml(Other?.Description || "") }
        if (sectionId === "SKILL_DIG") data = { [t('cvLabelSkillsComputer')]: getTextFromHtml(Computer?.Description || "") }

        if (sectionId === "EXPERTISE_AREA") data = { [t('cvLabelKeyword')]: ExpertiseArea.map(e => ({ [t('keyword')]: e.Title || "" })) }
        if (sectionId === "ABILITY") data = { [t('cvLabelAbility')]: Ability.map(e => ({ [t("cvLabelAbility")]: e.Title || "", [t("seniority")]: t(getCvPickerItemLabel("ability", (e.Level || 0))) })) }
        if (sectionId === "FOREIGN_LANGUAGE")
            data = {
                [t('cvLabelForeignLanguage')]: ForeignLanguage.map(e => ({
                    [t('language')]: e.Description.Label || t("notSpecified"),
                    [t('certificates')]: e.Certificate.map(c => ({ [t('certificate')]: c.Title || "" })),
                    [t('autoVal')]: {
                        [t('cvLabelForeignLanguageListening')]: e.ProficiencyLevel.Listening || "",
                        [t('cvLabelForeignLanguageReading')]: e.ProficiencyLevel.Reading || "",
                        [t('cvLabelForeignLanguageSpokenInteraction')]: e.ProficiencyLevel.SpokenInteraction || "",
                        [t('cvLabelForeignLanguageSpokenProduction')]: e.ProficiencyLevel.SpokenProduction || "",
                        [t('cvLabelForeignLanguageWriting')]: e.ProficiencyLevel.Writing || "",
                    }
                }))
            }

        if (sectionId === "WORK") data = { [t('cvLabelExperience')]: WorkExperience.map(e => getWorkItem(e)) }
        if (sectionId === "WORK_ITEM") data = { [t('cvLabelExperience')]: getWorkItem(item) }

        if (sectionId === "EDUCATION") data = { [t('cvLabelEducation')]: Education.map(e => getEducationItem(e)) }
        if (sectionId === "EDUCATION_ITEM") data = { [t('cvLabelEducation')]: getEducationItem(item) }

        if (sectionId === "ADDITIONAL_INFO") data = { [t('cvLabelAchievement')]: Achievement.map(e => getAchievementItem(e)) }
        if (sectionId === "ADDITIONAL_INFO_ITEM") data = { [t('cvLabelAchievement')]: getAchievementItem(item) }

        if (sectionId === "HEADLINE") data = { [t('cvLabelHeadline')]: t(getCvPickerItemLabel("headline", Headline.Type.Code)), [t('description')]: Headline.Description?.Label || "" }

        return data

    },


    // ------------------------------------------------------------------------------------------------------
    hasCvCapability: (cv, capId) => modelCatalogue.find(o => o.code === (cv.Metadata.modelType || "london")).capabilities.includes(capId),

    // ------------------------------------------------------------------------------------------------------
    stringToCvDate: stringToCvDate,


    // ------------------------------------------------------------------------------------------------------
    jsonToSimpleJson: cv => ({
        abilities: (cv.SkillsPassport.LearnerInfo.Ability || []).map(e => e.Title),
        expertiseAreas: (cv.SkillsPassport.LearnerInfo.ExpertiseArea || []).map(e => e.Title),
        results: (cv.SkillsPassport.LearnerInfo.Achievement || []).map(e => `${e.Title.Label}: ${e.Description.replace(/<[^>]*>?/gm, '')}`),
        headline: `${cv.SkillsPassport.LearnerInfo.Headline.Type.Label}: ${cv.SkillsPassport.LearnerInfo.Headline.Description.Label}`,
        education: (cv.SkillsPassport.LearnerInfo.Education || []).map(e => ({ title: `${e.Title} - ${e.Organisation.Name}`, Activities: e.Activities.replace(/<[^>]*>?/gm, ''), period: e.Period })),
        work: (cv.SkillsPassport.LearnerInfo.WorkExperience || []).map(e => ({ title: `${e.Position.Label} - ${e.Employer.Name}`, Activities: e.Activities.replace(/<[^>]*>?/gm, ''), period: e.Period })),
        summary: cv.SkillsPassport.LearnerInfo.Identification.Summary && (cv.SkillsPassport.LearnerInfo.Identification.Summary.Description || "").replace(/<[^>]*>?/gm, ''),
        contacts: {
            name: `${cv.SkillsPassport.LearnerInfo.Identification.PersonName.FirstName} ${cv.SkillsPassport.LearnerInfo.Identification.PersonName.Surname}`,
            professionalTitle: cv.SkillsPassport.LearnerInfo.Identification.ProfessionalTitle,
            address: cv.SkillsPassport.LearnerInfo.Identification.ContactInfo.Address.Contact.AddressLine,
            email: cv.SkillsPassport.LearnerInfo.Identification.ContactInfo.Email.Contact,
            phones: (cv.SkillsPassport.LearnerInfo.Identification.ContactInfo.Telephone || []).map(e => e.Contact),
            websites: (cv.SkillsPassport.LearnerInfo.Identification.ContactInfo.Website || []).map(e => e.Contact),
            socials: (cv.SkillsPassport.LearnerInfo.Identification.ContactInfo.SocialAccount || []).map(e => e.Contact),
            massaging: (cv.SkillsPassport.LearnerInfo.Identification.ContactInfo.InstantMessaging || []).map(e => e.Contact),
        },
        skills: {
            Communication: cv.SkillsPassport.LearnerInfo.Skills.Communication && (cv.SkillsPassport.LearnerInfo.Skills.Communication.Description || "").replace(/<[^>]*>?/gm, ''),
            Computer: cv.SkillsPassport.LearnerInfo.Skills.Computer && (cv.SkillsPassport.LearnerInfo.Skills.Computer.Description || "").replace(/<[^>]*>?/gm, ''),
            JobRelated: cv.SkillsPassport.LearnerInfo.Skills.JobRelated && (cv.SkillsPassport.LearnerInfo.Skills.JobRelated.Description || "").replace(/<[^>]*>?/gm, ''),
            Organisational: cv.SkillsPassport.LearnerInfo.Skills.Organisational && (cv.SkillsPassport.LearnerInfo.Skills.Organisational.Description || "").replace(/<[^>]*>?/gm, ''),
            Other: cv.SkillsPassport.LearnerInfo.Skills.Other && (cv.SkillsPassport.LearnerInfo.Skills.Other.Description || "").replace(/<[^>]*>?/gm, ''),
            Linguistic: (cv.SkillsPassport.LearnerInfo.Skills.Linguistic.ForeignLanguage || []).map(e => `${e.Description.Label} - levels: ${JSON.stringify(e.ProficiencyLevel)}`)
        }
    }),


    dataToJson: (dt, createFbIdFnc) => {

        const { certifications = [], courses = [], works = [], educations = [], keywords = [], abilities = [], summary = "", skills = {} } = dt;
        const { communication = "", other = "", organisational = "", jobRelated = "", computer = "" } = skills || {};

        let Achievement = [];
        certifications instanceof Array && !_.isEmpty(certifications) && Achievement.push({
            Id: createFbIdFnc(),
            Title: { Code: "certifications", Label: "Certifications" },
            Description: "<ul>" + certifications.map(e => `<li>${e.name}${e.organisation ? " (<i>" + e.organisation + "</i>)" : ""}</li>`).join("") + "</ul>"
        })

        courses instanceof Array && !_.isEmpty(courses) && Achievement.push({
            Id: createFbIdFnc(),
            Title: { Code: "courses", Label: "Courses" },
            Description: "<ul>" + courses.map(e => `<li>${e}</li>`).join("") + "</ul>"
        })

        return {
            SkillsPassport: {
                LearnerInfo: {
                    Identification: {
                        Summary: { Description: summary || "" },
                    },
                    WorkExperience: !(works instanceof Array) || _.isEmpty(works) ? [] : (works || []).map(e => ({
                        Id: createFbIdFnc(),
                        Activities: e.responsibility || "",
                        Position: { Label: e.position || "" },
                        Employer: { Name: e.company || "", ContactInfo: { Address: { Contact: { Municipality: "" } } }, Sector: { Label: "" } },
                        Period: { Current: false, From: stringToCvDate(), To: stringToCvDate() }
                    })),
                    Education: !(educations instanceof Array) || _.isEmpty(educations) ? [] : (educations || []).map(e => ({
                        Id: createFbIdFnc(),
                        Activities: e.description || "",
                        Title: e.qualification || "",
                        Organisation: { Name: e.organization || "", ContactInfo: { Address: { Contact: { Municipality: "" } } } },
                        Period: { Current: false, From: stringToCvDate(), To: stringToCvDate() }
                    })),
                    ExpertiseArea: !(keywords instanceof Array) || _.isEmpty(keywords) ? [] : (keywords || []).map(e => ({ Id: createFbIdFnc(), Title: e || "" })),
                    Ability: !(abilities instanceof Array) || _.isEmpty(abilities) ? [] : (abilities || []).map(e => ({ Id: createFbIdFnc(), Title: e, Level: _.random(2, 5) })),
                    Skills: {
                        Other: { Description: other || "" },
                        Communication: { Description: communication || "" },
                        Organisational: { Description: organisational || "" },
                        JobRelated: { Description: jobRelated || "" },
                        Computer: { Description: computer || "" },
                    },
                    Achievement,
                },
            }
        }
    },


    importDataToJson: importDataToJson,


    linkedinToJson: (parsing, userinfo, createFbIdFnc) => {

        const { given_name, family_name, email } = userinfo
        const { certifications, honors, projects, volunteering, courses } = parsing
        const { profileId, summary, firstName, lastName, headline, geo = {} } = parsing
        const { position = [], educations = [], skills = [], supportedLocales = [] } = parsing

        const contryCode = !_.isEmpty(languages[(supportedLocales[0]?.country || "").toLowerCase()]) && (supportedLocales[0]?.country || "").toLowerCase()
        let Achievement = [], MotherTongue = [], ForeignLanguage = [];

        certifications instanceof Array && !_.isEmpty(certifications) && Achievement.push({
            Id: createFbIdFnc(),
            Title: { Code: "certifications", Label: "Certifications" },
            Description: "<ul>" + certifications.map(e => `<li>${e.name}${e.authority ? " (<i>" + e.authority + "</i>)" : ""}</li>`).join("") + "</ul>"
        })

        honors instanceof Array && !_.isEmpty(honors) && Achievement.push({
            Id: createFbIdFnc(),
            Title: { Code: "honors_awards", Label: "Awards" },
            Description: "<ul>" + honors.map(e => `<li>${e.title}${e.issuer ? " (<i>" + e.issuer + "</i>)" : ""}${e.description ? (" :" + e.description) : ""}</li>`).join("") + "</ul>"
        })

        projects?.items instanceof Array && !_.isEmpty(projects?.items) && Achievement.push({
            Id: createFbIdFnc(),
            Title: { Code: "projects", Label: "Projects" },
            Description: "<ul>" + projects?.items.map(e => `<li>${e.title}${e.description ? (": " + e.description) : ""}</li>`).join("") + "</ul>"
        })

        courses instanceof Array && !_.isEmpty(courses) && Achievement.push({
            Id: createFbIdFnc(),
            Title: { Code: "courses", Label: "Courses" },
            Description: "<ul>" + courses.map(e => `<li>${e.name}${e.number ? " (<i>" + e.number + "</i>)" : ""}</li>`).join("") + "</ul>"
        })

        volunteering instanceof Array && !_.isEmpty(volunteering) && Achievement.push({
            Id: createFbIdFnc(),
            Title: { Code: t("volunteering"), Label: t("volunteering") },
            Description: "<ul>" + volunteering.map(e => `<li>${e.title}${e.companyName ? (": " + e.companyName) : ""}</li>`).join("") + "</ul>"
        })

        if (parsing.languages instanceof Array && !_.isEmpty(parsing.languages) && contryCode)
            _.forEach(parsing.languages, ({ name, proficiency }) => {
                const appLng = languages[contryCode].find(e => e.label === (name || "").toLowerCase())
                if (!appLng) return

                proficiency === "NATIVE_OR_BILINGUAL" ?
                    MotherTongue.push({ Description: { Code: appLng.code, Label: appLng.label } }) :
                    ForeignLanguage.push({
                        Id: createFbIdFnc(),
                        Description: { Code: appLng.code, Label: appLng.label },
                        Certificate: [],
                        ProficiencyLevel: { Listening: "", Reading: "", SpokenInteraction: "", SpokenProduction: "", Writing: "" },
                    })
            })

        return {
            Metadata: { modelType: "london", linkedinUrl: `https://www.linkedin.com/in/${profileId || ""}`, linkedinId: profileId || "" },
            SkillsPassport: {
                LearnerInfo: {
                    Identification: {
                        Summary: { Description: summary || "" },
                        PersonName: { FirstName: (given_name || firstName) || "", Surname: (family_name || lastName) || "" },
                        ProfessionalTitle: headline || "",
                        ContactInfo: {
                            Address: { Contact: { AddressLine: geo?.full || "", PostalCode: "", Municipality: "" } },
                            Email: { Contact: email || "" },
                            SocialAccount: !profileId ? [] : [{ Id: createFbIdFnc(), Contact: `https://www.linkedin.com/in/${profileId}`, Use: { Code: "linkedin", Label: "linkedin" } }],
                        },
                    },
                    WorkExperience: !(position instanceof Array) || _.isEmpty(position) ? [] : (position || []).map(e => ({
                        Id: createFbIdFnc(),
                        Activities: e.description || "",
                        Position: { Label: e.title || "" },
                        Employer: { Name: e.companyName || "", ContactInfo: { Address: { Contact: { Municipality: e.location || "" } } }, Sector: { Label: e.companyIndustry || "" } },
                        Period: {
                            Current: (e.end?.year === 0),
                            From: stringToCvDate(e.start?.year > 0 && e.start?.month > 0 ? `${e.start?.year}-${e.start?.month}` : e.start?.year > 0 ? `${e.start?.year}` : null),
                            To: stringToCvDate(e.end?.year > 0 && e.end?.month > 0 ? `${e.end?.year}-${e.end?.month}` : e.end?.year > 0 ? `${e.end?.year}` : null),
                        }
                    })),
                    Education: !(educations instanceof Array) || _.isEmpty(educations) ? [] : (educations || []).map(e => ({
                        Id: createFbIdFnc(),
                        Activities: e.description || "",
                        Title: e.degree || "",
                        Organisation: { Name: e.schoolName || "", ContactInfo: { Address: { Contact: { Municipality: "" } } } },
                        Period: {
                            Current: (e.end?.year === 0),
                            From: stringToCvDate(e.start?.year > 0 && e.start?.month > 0 ? `${e.start?.year}-${e.start?.month}` : e.start?.year > 0 ? `${e.start?.year}` : null),
                            To: stringToCvDate(e.end?.year > 0 && e.end?.month > 0 ? `${e.end?.year}-${e.end?.month}` : e.end?.year > 0 ? `${e.end?.year}` : null),
                        }
                    })),
                    Ability: !(skills instanceof Array) || _.isEmpty(skills) ? [] : (skills || []).filter(e => (e.endorsementsCount || 0) > 0).map(e => ({ Id: createFbIdFnc(), Title: e.name || "", Level: _.random(2, 5) })),
                    ExpertiseArea: !(skills instanceof Array) || _.isEmpty(skills) ? [] : (skills || []).filter(e => (e.endorsementsCount || 0) === 0).map(e => ({ Id: createFbIdFnc(), Title: e.name || "" })),
                    Skills: { Linguistic: { MotherTongue, ForeignLanguage } },
                    Achievement,
                },
            }
        }

    },


    base64FileToJson: async (user, base64File, typeFile, importApiFnc, createFbIdFnc, decodeB64Fnc, bufferFnc) => {

        let importV1, importV2;
        let cvContent, photoContent, importedCv, importedPhoto, removeItems = false;

        // PDF import
        if (typeFile === "application/pdf") {

            const pdfDoc = await PDFDocument.load(base64File, { updateMetadata: false });
            const totalPages = pdfDoc.getPageCount()
            const producer = pdfDoc.getProducer();
            const chunkSize = pdfDoc['getInfoDict']().lookup(PDFName.of('Cvfy'));

            importV1 = (producer === "CurriculifyApplication");
            importV2 = (chunkSize);

            if (importV1) {
                // check 1: json validity -> bloker if not valid ||| check 2: photo parser -> no blocker if not valid: replace with default one
                try { cvContent = JSON.parse(pdfDoc.getSubject()); } catch (e) { await Promise.reject({ code: 'import/pdf-corrupted' }); }
                try { photoContent = JSON.parse(pdfDoc.getCreator()); } catch (e) { photoContent = { "MimeType": "", "Data": "", "Metadata": [] }; }
                importedCv = _.cloneDeep(cvContent);
                importedCv.SkillsPassport.LearnerInfo.Identification.Photo = _.cloneDeep(photoContent);
            };// importV1

            if (importV2) {

                const chunkLength = parseInt(chunkSize.decodeText());
                let chunkString = "";
                try {
                    for (let i = 0; i < chunkLength; i++) chunkString = chunkString + decodeB64Fnc(pdfDoc['getInfoDict']().lookup(PDFName.of(`Cvfy${i}`)).decodeText());;
                    cvContent = JSON.parse(chunkString);
                } catch (e) {

                    cvContent = false;
                    try {
                        for (let i = 0; i < chunkLength; i++) chunkString = chunkString + pdfDoc['getInfoDict']().lookup(PDFName.of(`Cvfy${i}`)).decodeText();;
                        cvContent = JSON.parse(chunkString);
                    } catch (e) { await Promise.reject({ code: 'import/pdf-corrupted' }); }
                }
                importedCv = _.cloneDeep(cvContent);
            };// importV2

            // 
            if (!importV1 && !importV2) {
                if (totalPages > 6) return await Promise.reject({ code: 'import/pdf-exceed-pages' }) // maximum 4 pages of CV allowed

                // get profile image
                if (bufferFnc)
                    pdfDoc.context.enumerateIndirectObjects().forEach(x => {
                        const pdfObject = x[1];
                        if (!(pdfObject instanceof PDFRawStream)) return;
                        const b64 = bufferFnc.from(pdfObject.contents).toString('base64')
                        const mime = b64.substring(0, '/9j/'.length) === '/9j/' ? "image/jpeg" : b64.substring(0, 'iVBORw0KGgo'.length) === 'iVBORw0KGgo' ? "image/png" : null
                        if (mime) importedPhoto = { base64: b64, mime }
                    })
            }

        } // END PDF IMPORT

        // if no exising import system or doc file call api
        if ((!importV1 && !importV2) || typeFile !== "application/pdf") {

            const res = await importApiFnc({ data: base64File });
            importedCv = Object.assign({}, defaultCvObject, importDataToJson(res.data, user, createFbIdFnc));

        } // end if

        // use default model if user does not have caps
        const model = modelCatalogue.find(o => o.code === (importedCv.Metadata.modelType || "london"))
        const londonModel = modelCatalogue.filter(o => o.code === "london");

        // check template
        if (!userHasCap(user, 'TEMPLATE') && !model.isFree) {
            if (importedCv.Metadata.modelArrange && (model.layout !== londonModel.layout))
                delete importedCv.Metadata.modelArrange

            importedCv.Metadata.modelType = "london";
            removeItems = true;
        }

        return { importedCv, importedPhoto, removeItems };


    },
}