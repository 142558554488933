import { useMemo } from "react"
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import { Card, CardBody, CardFooter, Image } from "@nextui-org/react";

import { setCv } from "cvpop-redux-sdk";
import { getCvImage, getCvInitials } from 'cvpop-utility-sdk';
import { flags } from "cvpop-assets-sdk"
import { t } from "cvpop-localization-sdk"

import moment from "moment"


const WebCVCard = ({ cv }) => {


    // ------------------------------------------------------------------------------------------------------------------------
    const { platform } = useParams()

    // ------------------------------------------------------------------------------------------------------------------------
    const n = useNavigate()
    const d = useDispatch()

    // ------------------------------------------------------------------------------------------------------------------------
    const cvImg = useMemo(() => getCvImage(cv, platform), [cv, platform])
    const initials = useMemo(() => getCvInitials(cv), [cv])
    const title = useMemo(() => cv.Metadata.name, [cv])
    const caption = useMemo(() => `${t('lastUpdate')} ${moment(cv.Metadata.updatedAt).fromNow()}`, [cv])
    const flag = useMemo(() => flags[cv.Metadata.lng], [cv]);


    // ------------------------------------------------------------------------------------------------------------------------
    return (
        <Card shadow="sm" isPressable onPress={() => {
            d(setCv({ cv }));
            n(`/app/${platform}/cv/edit`)
        }}>
            <CardBody className="p-0">
                {
                    cvImg ? (
                        <Image
                            alt={title}
                            width="100%"
                            radius="none"
                            className="w-full object-cover h-[250px]"
                            src={cvImg.uri || cvImg}
                        />
                    ) : (
                        <div className="w-full h-[250px] flex justify-center items-center">
                            <span className="text-8xl font-semibold">{initials}</span>
                        </div>
                    )
                }
                
            </CardBody>
            <CardFooter className="text-small ">
                <div className="flex flex-col text-left w-full">
                    <p className="font-bold text-left">{title}</p>
                    <p className="text-xs font-light text-default-400">{caption}</p>
                </div>
                <div className="self-center ml-3">
                    <img alt={"CV"} src={flag} className="w-6" />
                </div>
            </CardFooter>
        </Card>
    )
}

export default WebCVCard