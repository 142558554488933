import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input } from "@nextui-org/react";
import { useNavigate, useParams } from 'react-router-dom';
import { produce } from "immer"
import { updateProfile } from 'firebase/auth';

import { regex } from 'cvpop-constants-sdk';
import { setUser } from 'cvpop-redux-sdk'
import { t } from "cvpop-localization-sdk";

import _ from "lodash"

import { auth } from '../../../controllers/firebase';
import { errorHandler } from '../../../controllers/app';

import WebIcon from '../../atoms/WebIcon';


const WebIntroCommonItemInput = ({ type }) => {

    // ------------------------------------------------------------------------------------------------------
    const { user } = useSelector(state => state.userReducer)
    const { platform } = useParams()

    // ------------------------------------------------------------------------------------------------------
    const d = useDispatch();
    const n = useNavigate()
    const fbuser = auth.currentUser;

    // ------------------------------------------------------------------------------------------------------
    const title = useMemo(() => {
        if (type === "first-name") return t("introOurselves")
        if (type === "last-name") return `${t("hello")} ${user.firstName}, ${t("whatsYourSurname")}`
        if (type === "job-title") return `${t("niceToMeetYou")} ${user.firstName}!`
        if (type === "email") return t("introEmailTitle")
        if (type === "phone") return t("introPhoneTitle")
    }, [type, user])

    const message = useMemo(() => {
        if (type === "job-title") return t("whatsYourJobTitle")
        if (type === "email") return t("introEmailMsg")
        if (type === "phone") return t("introPhoneMsg")
    }, [type])

    const autoCapitalize = useMemo(() => type === "email" ? "none" : "words", [type])

    const placeholder = useMemo(() => {
        if (type === "first-name") return t("whatsYourName")
        if (type === "last-name") return t("lastNamePh")
        if (type === "job-title") return t("jobTitlePh")
        if (type === "email") return t("emailPh")
        if (type === "phone") return t("introPhoneTitle")
    }, [type])

    const initialValue = useMemo(() => {
        if (type === "first-name") return user.firstName || ""
        if (type === "last-name") return user.lastName || ""
        if (type === "job-title") return user.professionalTitle || ""
        if (type === "email") return user.email || ""
        if (type === "phone") return user.phone || ""
    }, [type, user.email, user.firstName, user.lastName, user.phone, user.professionalTitle])

    const nextScreenPath = useMemo(() => {
        if (type === "first-name") return `/app/${platform}/onboarding/last-name`
        if (type === "last-name") return `/app/${platform}/onboarding/job-title`
        if (type === "job-title") return `/app/${platform}/onboarding/new-profile`
        if (type === "email") return `/app/${platform}/onboarding/phone`
        if (type === "phone") return `/app/${platform}/onboarding/location`
    }, [type, platform])

    // ------------------------------------------------------------------------------------------------------
    const [text, setText] = useState(initialValue)

    // ------------------------------------------------------------------------------------------------------
    const setInput = useCallback(() => {

        d(setUser({
            user: produce(user, d => {
                if (type === "first-name") d.firstName = text
                if (type === "last-name") d.lastName = text
                if (type === "job-title") d.professionalTitle = text
                if (type === "email") d.email = text
                if (type === "phone") d.phone = text
            })
        }))

        if (type === "first-name" && text !== fbuser?.displayName)
            updateProfile(fbuser, { displayName: text }).catch(e => errorHandler("err_set_account_name", e))

        n(nextScreenPath)

    }, [n, d, type, fbuser, user, text, nextScreenPath])

    const isDisabled = useCallback(() => {
        if (type === "email") return (_.isEmpty(text) || !((text || "").toLowerCase().match(regex.mail)))
        return _.isEmpty(text)
    }, [type, text])

    const onSubmitEditing = useCallback(() => {
        if (type === "email") return text && text.toLowerCase().match(regex.mail) && setInput()
        text && setInput()
    }, [type, setInput, text])

    // ------------------------------------------------------------------------------------------------------
    return (
        <div className=' bg-content1 flex justify-center items-center flex-col' style={{ minHeight: `calc(100vh - ${platform === "web" ? "4rem" : "0px"})` }}>
            <div className='max-w-md min-w-96 m-3'>

                {
                    (type !== "first-name" && platform !== "web") && (
                        <Button isIconOnly variant={"light"} className='mb-3' onClick={() => n(-1)}>
                            <WebIcon name={"west"} />
                        </Button>
                    )
                }

                <h1 className='text-3xl mb-3 font-bold'>
                    {title}
                </h1>

                {
                    message && (
                        <h1 className='text-xl mb-3'>
                            {message}
                        </h1>
                    )
                }

                <Input
                    defaultValue={initialValue}
                    size={"lg"}
                    autoFocus
                    isClearable
                    autoCapitalize={autoCapitalize}
                    onChange={({ target }) => setText(target.value)}
                    onKeyDown={({ key }) => (key === "Enter" && onSubmitEditing())}
                    placeholder={placeholder}
                />

                <Button
                    color={"primary"}
                    className='mt-3'
                    isDisabled={isDisabled()}
                    fullWidth
                    onPress={() => setInput()}
                >
                    {t('continue')}
                </Button>

                {
                    (type === "email" || type === "phone") && (
                        <Button className='mt-3' color='primary' fullWidth variant={"light"} onClick={() => n(nextScreenPath)}>
                            {t("skip")}
                        </Button>
                    )
                }
            </div>
        </div>
    )
    // return (
    //     <SafeAreaView style={[layout.flex]}>
    //         <KeyboardAvoidingView
    //             behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
    //             keyboardVerticalOffset={i.bottom + Platform.select({ ios: 20, android: 47 })}
    //             style={[layout.flex]}
    //         >
    //             <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false}>

    //                 <View style={[layout.flex, layout.centerScrollView]}>
    //                     <View style={[layout.flex]}>

    //                         <AppText style={[typography.largeTitleEmphasized, margin.T100, margin.A20, message && margin.B10]}>
    //                             {title}
    //                         </AppText>
    //                         {
    //                             message && (
    //                                 <AppText style={[typography.title3, margin.A20, margin.T0]}>
    //                                     {message}
    //                                 </AppText>
    //                             )
    //                         }

    //                         <AppCard style={[]}>
    //                             <AppInput
    //                                 testID={testId}
    //                                 autoCapitalize={autoCapitalize}
    //                                 autoFocus
    //                                 keyboardType={keyboardType}
    //                                 autoComplete={autoComplete}
    //                                 textContentType={textContentType}
    //                                 returnKeyType={"next"}
    //                                 placeholder={placeholder}
    //                                 style={[typography.title3Emphasized]}
    //                                 defaultValue={text}
    //                                 onChangeText={t => setText((t || "").trim())}
    //                                 onSubmitEditing={onSubmitEditing}
    //                             />
    //                         </AppCard>
    //                     </View>

    //                     <AppButton
    //                         testID="nextBtn"
    //                         disabled={isDisabled()}
    //                         text={t('continue')}
    //                         onPress={() => setInput()}
    //                     />

    //                 </View>
    //             </TouchableWithoutFeedback>
    //         </KeyboardAvoidingView>
    //     </SafeAreaView >
    // )
};

export default WebIntroCommonItemInput