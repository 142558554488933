import React, { useCallback, useMemo } from "react";
import { Card, CardBody, Input, Select, SelectItem, Button } from "@nextui-org/react";

import { useSelector, useDispatch } from 'react-redux'
import { produce } from "immer";

import { cvPickerItems } from "cvpop-constants-sdk";
import { getCvPickerItemLabel } from "cvpop-utility-sdk";
import { nationalities, countries } from "cvpop-collections-sdk"
import { setCv } from "cvpop-redux-sdk";
import { t } from "cvpop-localization-sdk"

import _ from "lodash"

import { useColorScheme } from "../../../../controllers/hooks";


import WebHeader from "../../../molecules/views/WebHeader";
import WebListItemHeaderView from "../../../molecules/views/WebListItemHeaderView";
import WebAIAssistantButton from "../../../molecules/buttons/WebAIAssistantButton";


const WebPersonalInfo = () => {

    // ------------------------------------------------------------------------------------------------------
    const { cv } = useSelector(state => state.cvReducer)
    const { user } = useSelector(state => state.userReducer)

    // ------------------------------------------------------------------------------------------------------
    const { language } = user

    // ------------------------------------------------------------------------------------------------------
    const d = useDispatch()
    const cs = useColorScheme()

    // ------------------------------------------------------------------------------------------------------
    const { lng } = cv.Metadata
    const { PersonName, Demographics, ContactInfo, ProfessionalTitle } = cv.SkillsPassport.LearnerInfo.Identification
    const { Birthdate, Nationality, Gender } = Demographics
    const { Address, Email } = ContactInfo
    const { Year = null, Month = null, Day = null } = Birthdate
    const { AddressLine, PostalCode, Municipality, Country } = Address.Contact

    // ------------------------------------------------------------------------------------------------------
    const notSetItem = useMemo(() => ({ label: t("notSpecified"), code: "" }), [])
    const countryList = useMemo(() => {
        const cvVal = cv.SkillsPassport.LearnerInfo.Identification.ContactInfo.Address.Contact.Country
        const cValMap = { code: cvVal?.Code || "US", label: cvVal?.Label || "" } // US to fake uniqueBy lodash function
        return [notSetItem, ..._.orderBy(_.uniqBy([...(countries[language] || countries["en"]), cValMap], "code"), [e => e.label.toLowerCase()], ['asc'])]
    }, [language, notSetItem, cv])

    const nationalityList = useMemo(() => {
        const cvVal = cv.SkillsPassport.LearnerInfo.Identification.Demographics.Nationality[0]
        const cValMap = { code: cvVal?.Code || "US", label: cvVal?.Label || "" } // US to fake uniqueBy lodash function
        return [notSetItem, ..._.orderBy(_.uniqBy([...(nationalities[language] || nationalities["en"]), cValMap], "code"), [e => e.label.toLowerCase()], ['asc'])]
    }, [language, notSetItem, cv])

    // ------------------------------------------------------------------------------------------------------
    const editCv = useCallback(cv => { d(setCv({ cv })) }, [d])

    const onAddCustomCountry = useCallback(() => {
        const value = (window.prompt(`${t('addNewItem')}\n\n${t('addNewCustomItemMsg')}`) || "").trim()
        if (value) editCv(produce(cv, d => void _.set(d, "SkillsPassport.LearnerInfo.Identification.ContactInfo.Address.Contact.Country", { Code: value, Label: value })));
    }, [cv, d])

    const onAddCustomNationality = useCallback(() => {
        const value = (window.prompt(`${t('addNewItem')}\n\n${t('addNewCustomItemMsg')}`) || "").trim()
        if (value) editCv(produce(cv, d => void _.set(d, "SkillsPassport.LearnerInfo.Identification.Demographics.Nationality", [{ Code: value, Label: value }])));
    }, [cv, d])

    // ------------------------------------------------------------------------------------------------------
    return (
        <div>
            <WebHeader title={t('personalData')} />
            <div className="p-5 pt-0">

                <WebListItemHeaderView text={t('baseInfo')} />
                <Card shadow={"none"}>
                    <CardBody>
                        <div className="flex items-center">
                            <div className="w-full">
                                <Input
                                    label={t("name")}
                                    defaultValue={PersonName.FirstName || ""}
                                    onChange={({ target }) => editCv(produce(cv, d => void _.set(d, "SkillsPassport.LearnerInfo.Identification.PersonName.FirstName", target.value)))}
                                />
                                <Input
                                    label={t("surname")}
                                    defaultValue={PersonName.Surname || ""}
                                    onChange={({ target }) => editCv(produce(cv, d => void _.set(d, "SkillsPassport.LearnerInfo.Identification.PersonName.Surname", target.value)))}
                                    className="mt-2"
                                />
                            </div>
                        </div>
                        <Input
                            label={t("email")}
                            type="email"
                            defaultValue={Email.Contact || ""}
                            onChange={({ target }) => editCv(produce(cv, d => void _.set(d, "SkillsPassport.LearnerInfo.Identification.ContactInfo.Email.Contact", target.value)))}
                            className="mt-2"
                        />
                        <Input
                            label={t("jobTitle")}
                            defaultValue={ProfessionalTitle || ""}
                            onChange={({ target }) => editCv(produce(cv, d => void _.set(d, "SkillsPassport.LearnerInfo.Identification.ProfessionalTitle", target.value)))}
                            className="mt-2"
                        />
                    </CardBody>
                </Card>

                <WebListItemHeaderView text={t('address')} />
                <Card shadow={"none"}>
                    <CardBody>
                        <Input
                            label={t("street")}
                            defaultValue={AddressLine}
                            onChange={({ target }) => editCv(produce(cv, d => void _.set(d, "SkillsPassport.LearnerInfo.Identification.ContactInfo.Address.Contact.AddressLine", target.value)))}
                        />
                        <Input
                            label={t("postalCode")}
                            defaultValue={PostalCode}
                            onChange={({ target }) => editCv(produce(cv, d => void _.set(d, "SkillsPassport.LearnerInfo.Identification.ContactInfo.Address.Contact.PostalCode", target.value)))}
                            className="mt-2"
                        />
                        <Input
                            label={t("city")}
                            defaultValue={Municipality}
                            onChange={({ target }) => editCv(produce(cv, d => void _.set(d, "SkillsPassport.LearnerInfo.Identification.ContactInfo.Address.Contact.Municipality", target.value)))}
                            className="mt-2"
                        />

                        <div className={`flex items-center`}>
                            <Select
                                label={t('country')}
                                selectedKeys={[Country.Code || ""]}
                                className="mt-2"
                                classNames={{ popoverContent: `${cs} text-foreground` }}
                                textValue={Country.Label || ""}
                                disallowEmptySelection
                                onChange={({ target }) => {
                                    const e = countryList.find(e => e.code === target.value)
                                    editCv(produce(cv, d => void _.set(d, "SkillsPassport.LearnerInfo.Identification.ContactInfo.Address.Contact.Country", { Code: e.code, Label: e.label })))
                                }}
                            >
                                {countryList.map(el => (<SelectItem key={el.code} value={el.code}>{el.label}</SelectItem>))}
                            </Select>

                            <Button isIconOnly color="primary" aria-label="Like" className="mt-3 ml-3" onPress={onAddCustomCountry}>
                                <span className="material-symbols-outlined">add</span>
                            </Button>
                        </div>
                    </CardBody>
                </Card>

                <WebListItemHeaderView text={t('otherFields')} />
                <Card shadow={"none"}>
                    <CardBody>
                        <Select
                            label={t('gender')}
                            defaultSelectedKeys={[Gender.Code]}
                            classNames={{ popoverContent: `${cs} text-foreground` }}
                            disallowEmptySelection
                            textValue={t(getCvPickerItemLabel("gender", Gender.Code), { locale: lng })}
                            onChange={({ target }) => {
                                const e = cvPickerItems.gender().find(e => e.code === target.value)
                                editCv(produce(cv, d => void _.set(d, "SkillsPassport.LearnerInfo.Identification.Demographics.Gender", { Code: e.code, Label: e.label })));
                            }}
                        >
                            {cvPickerItems.gender().map(el => (<SelectItem key={el.code} value={el.code}>{t(el.label)}</SelectItem>))}
                        </Select>

                        <Input
                            label={t("birthDate")}
                            type={"date"}
                            className="mt-2"
                            defaultValue={Year > 0 ? `${Year}-${Month < 10 ? "0" : ""}${Month}-${Day < 10 ? "0" : ""}${Day}` : undefined}
                            onChange={({ target }) => {
                                const jsDate = new Date(target.value)
                                const cvDate = (target.value && jsDate.getFullYear() > 1900) ?
                                    { Day: jsDate.getDate(), Month: jsDate.getMonth() + 1, Year: jsDate.getFullYear() } :
                                    { Day: null, Month: null, Year: null }
                                editCv(produce(cv, d => void _.set(d, "SkillsPassport.LearnerInfo.Identification.Demographics.Birthdate", cvDate)))
                            }}

                        />

                        <div className={`flex items-center`}>
                            <Select
                                label={t('nationality')}
                                selectedKeys={[Nationality[0]?.Code || ""]}
                                className="mt-2"
                                classNames={{ popoverContent: `${cs} text-foreground` }}
                                disallowEmptySelection
                                textValue={Nationality[0]?.Label || ""}
                                onChange={({ target }) => {
                                    const e = nationalityList.find(e => e.code === target.value)
                                    const newValue = e.code ? [{ Code: e.code, Label: e.label }] : []
                                    editCv(produce(cv, d => void _.set(d, "SkillsPassport.LearnerInfo.Identification.Demographics.Nationality", newValue)));
                                }}
                            >
                                {nationalityList.map(el => (<SelectItem key={el.code} value={el.code}>{el.label}</SelectItem>))}
                            </Select>

                            <Button isIconOnly color="primary" aria-label="Like" className="mt-3 ml-3" onPress={onAddCustomNationality}>
                                <span className="material-symbols-outlined">add</span>
                            </Button>
                        </div>
                    </CardBody>
                </Card>

                <WebAIAssistantButton sectionId={"PERSONAL_DATA"} />

            </div>

        </div>

    )
}

export default WebPersonalInfo