import React, { useCallback, useMemo, useState } from "react";
import { Card, CardBody, Listbox, ListboxItem, Input } from "@nextui-org/react";
import { useSelector, useDispatch } from 'react-redux'
import { produce } from "immer";

import { languages } from 'cvpop-collections-sdk';
import { setCv } from "cvpop-redux-sdk";
import { t } from "cvpop-localization-sdk"

import _ from "lodash"
import WebHeader from "../../../molecules/views/WebHeader";


const WebMotherTongue = () => {

    // ------------------------------------------------------------------------------------------------------
    const { cv } = useSelector(state => state.cvReducer)
    const { user } = useSelector(state => state.userReducer)

    // ------------------------------------------------------------------------------------------------------
    const { language } = user

    // ------------------------------------------------------------------------------------------------------
    const d = useDispatch()

    // ------------------------------------------------------------------------------------------------------
    const [q, setQ] = useState("");

    // ------------------------------------------------------------------------------------------------------
    const cVal = useMemo(() => cv.SkillsPassport.LearnerInfo.Skills.Linguistic.MotherTongue, [cv])
    const cValMap = useMemo(() => _.map(cVal, ({ Description }) => ({ code: Description.Code, label: Description.Label })), [cVal])
    const list = useMemo(() => [...(languages[language] || languages["en"]), ...cValMap], [cValMap])
    const localizedList = useMemo(() => _.uniqBy(_.orderBy(list, [e => e.label.toLowerCase()], ['asc']), "code"), [list])
    const queryList = useMemo(() => {
        const query = _.filter(localizedList, _.flow(_.identity, _.values, _.join, _.toLower, _.partialRight(_.includes, (q || "").toLowerCase())))
        return query.length > 0 ? query : [{ code: q, label: q }];
    }, [q, localizedList])

    // const localizedList = useMemo(() => _.orderBy((languages[language] || languages["en"]), [e => e.label.toLowerCase()], ['asc']), [language])

    // ------------------------------------------------------------------------------------------------------
    const onItemPress = useCallback(item => {
        const newCv = produce(cv, d => {
            const motherTongue = d.SkillsPassport.LearnerInfo.Skills.Linguistic.MotherTongue;
            const index = motherTongue.findIndex(o => o.Description.Code === item.code);
            index !== -1 ? motherTongue.splice(index, 1) : motherTongue.push({ Description: { Code: item.code, Label: item.label } })
        })
        d(setCv({ cv: newCv }))
    }, [cv])


    // ------------------------------------------------------------------------------------------------------
    return (
        <div>
            <WebHeader title={t("cvLabelMotherTongue")} />
            <div className="p-5 pb-0">
                <Card shadow={"none"}>
                    <CardBody>
                        <Input 
                            onValueChange={v => setQ(v)}
                            placeholder={`${t("search")}...`} 
                            isClearable
                            startContent={<span className="material-symbols-outlined">search</span>} />
                    </CardBody>
                </Card>
            </div>

            <div className="p-5">
                <Card shadow={"none"}>
                    <CardBody>
                        <Listbox
                            aria-label="Multiple selection example"
                            variant="flat"
                            selectionMode="multiple"
                            defaultSelectedKeys={(cv.SkillsPassport.LearnerInfo.Skills.Linguistic.MotherTongue || []).map(({ Description }) => Description.Code)}
                        >
                            {queryList.map(el => (
                                <ListboxItem
                                    key={el.code}
                                    value={el.code}
                                    title={_.capitalize(el.label)}
                                    onClick={() => onItemPress(el)}
                                />
                            ))}
                        </Listbox>
                    </CardBody>
                </Card>
            </div>
        </div>

    )
}

export default WebMotherTongue