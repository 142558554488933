import { useMemo } from "react"
import { useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { Slider } from "@nextui-org/react";
import { produce } from "immer"

import { icons } from "cvpop-assets-sdk"
import { getCvImage } from "cvpop-utility-sdk"
import { setCv } from "cvpop-redux-sdk"
import { cvPickerItems, layout } from "cvpop-constants-sdk"
import { t } from "cvpop-localization-sdk"

import _ from "lodash"

import WebIcon from "../../../atoms/WebIcon"
import WebHeader from "../../../molecules/views/WebHeader"


const WebPhotFormat = () => {

    // ------------------------------------------------------------------------------------------------------------------------
    const { cv } = useSelector(state => state.cvReducer)
    const { platform } = useParams()

    // ------------------------------------------------------------------------------------------------------------------------
    const d = useDispatch()

    // ------------------------------------------------------------------------------------------------------------------------
    const { modelPhotoFormat, modelPhotoBgYPosition = 50 } = cv.Metadata

    // ------------------------------------------------------------------------------------------------------------------------
    const cvImg = useMemo(() => getCvImage(cv, platform) || icons.avatarcv, [cv, platform])

    // ------------------------------------------------------------------------------------------------------------------------
    return (
        <div>
            <WebHeader title={t("photoFormat")} />
            <div className="p-5 pb-0 flex justify-center">
                <Slider
                    step={1}
                    maxValue={100}
                    minValue={1}
                    startContent={<WebIcon name={"vertical_align_top"} />}
                    endContent={<WebIcon name={"vertical_align_bottom"} />}
                    onChangeEnd={v => d(setCv({ cv: produce(cv, d => { d.Metadata.modelPhotoBgYPosition = v }) }))}
                    defaultValue={modelPhotoBgYPosition}
                    className="max-w-md"
                />
            </div>

            <div className="grid grid-cols-3 gap-4 p-5">
                {
                    cvPickerItems.photoFormat().map(el => (
                        <a
                            key={`formatp${el.code}`}
                            className={`text-center`}
                            onClick={() => d(setCv({ cv: produce(cv, d => { d.Metadata.modelPhotoFormat = el.code }) }))}
                        >
                            <div
                                className={`p-1 aspect-square inline-block border-4 border-${(el.code === (modelPhotoFormat || "default")) ? "primary" : "default"}`}
                                style={{ ...layout.photoFormat()[el.code] }}

                            >
                                <img
                                    src={cvImg.uri || cvImg}
                                    className={`max-w-28 max-h-28 aspect-square object-cover inline-block`}
                                    style={{ ...layout.photoFormat()[el.code] }}
                                />
                            </div>
                        </a>
                    ))
                }
            </div>

        </div>
    )
}

export default WebPhotFormat