import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, Button } from "@nextui-org/react"
import { useNavigate, useParams } from "react-router-dom";
import { produce } from 'immer';

import { setCv, setUser } from 'cvpop-redux-sdk';
import { illustrations } from "cvpop-assets-sdk"
import { renderCvPreview } from 'cvpop-utility-sdk';
import { t } from "cvpop-localization-sdk";

import _ from "lodash"


const WebIntroFeatures = () => {

    // ------------------------------------------------------------------------------------------------------
    const { user } = useSelector(state => state.userReducer)
    const { cv, cvList } = useSelector(state => state.cvReducer)
    const { platform } = useParams()

    // ------------------------------------------------------------------------------------------------------
    const d = useDispatch()
    const n = useNavigate()

    // ------------------------------------------------------------------------------------------------------
    const addStyle = useMemo(() => (`.page{ transition: transform 0.3s ease; transform-origin: 0 0; transform: scale(0.5) }`), [])
    const html = useMemo(() => renderCvPreview(cv, user, addStyle).html, [cv, user, addStyle])

    // ------------------------------------------------------------------------------------------------------
    useEffect(() => { d(setUser({ user: produce(user, d => { d.isOnbordingSetUp = true }) })) }, [d]) // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (_.isEmpty(cv) && cvList.length > 0) d(setCv({ cv: cvList[0], ignoreListener: true }))
        d(setUser({ user: produce(user, d => { d.isOnbordingSetUp = true }) }))
    }, [user, cv, cvList, d])

    // ------------------------------------------------------------------------------------------------------
    return (
        <div className='flex justify-center items-center flex-col' style={{ minHeight: `calc(100vh - ${platform === "web" ? "4rem" : "0px"})` }}>
            <Card className='max-w-md m-3'>
                <CardBody>
                    {
                        !_.isEmpty(cv) ? (
                            <>
                                <div
                                    dangerouslySetInnerHTML={{ __html: html }}
                                    className="overflow-y-hidden overflow-x-hidden overscroll-none"
                                    style={{ padding: 25, height: 300 }}
                                />
                                <p className="font-sans font-bold text-2xl text-center mt-3">{t("hey")} {user.firstName}</p>
                                <p className="font-sans text-center mt-3">{t("introFeatMsg")}</p>
                            </>
                        ) : (
                            <>
                                <div className="max-w-80 self-center">
                                    <img alt={t("introFeatMsg")} src={illustrations.confidence} />
                                </div>
                                <p className="font-sans font-bold text-2xl text-center mt-3">{t("hey")} {user.firstName}</p>
                                <p className="font-sans text-center mt-3">{t("introFeatMsg")}</p>
                            </>
                        )
                    }
                    <Button
                        className='mt-3'
                        color='primary'
                        fullWidth
                        onClick={() => {
                            if (window.webkit?.messageHandlers["askForRate"]) return n(`/app/${platform}/onboarding/feedback`)
                            if (platform === "macos" || platform === "visionos") return n(`/app/${platform}/onboarding/paywall`, { state: { onboarding: true } })
                            if (platform === "web") return n(`/app/${platform}/onboarding/completition`)
                        }}
                    >
                        {t("next")}
                    </Button>

                </CardBody>

            </Card>
        </div>
    )
}

export default WebIntroFeatures