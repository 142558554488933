import React, { useCallback } from 'react';
import { Card, CardBody, Button } from "@nextui-org/react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { produce } from "immer"

import { setUser } from 'cvpop-redux-sdk';
import { t } from "cvpop-localization-sdk";
import { modelCatalogue } from 'cvpop-constants-sdk';

import WebIcon from '../../atoms/WebIcon';
import { models } from 'cvpop-assets-sdk';


const WebIntroCvLayout = () => {

    // ------------------------------------------------------------------------------------------------------
    const { user } = useSelector(state => state.userReducer)
    const { cvList } = useSelector(state => state.cvReducer)
    const { platform } = useParams()

    // ------------------------------------------------------------------------------------------------------
    const d = useDispatch()
    const n = useNavigate()

    // ------------------------------------------------------------------------------------------------------
    const { cvLayout } = user.introChoices || {};

    // ------------------------------------------------------------------------------------------------------
    const setCvLayout = useCallback(o => {

        d(setUser({ user: produce(user, d => { d.introChoices = { ...d.introChoices, cvLayout: o } }) }))
        n(`/app/${platform}/onboarding/${cvList.length > 0 ? "features" : "import-cv"}`)

    }, [user, cvList, platform, d, n])

    // ------------------------------------------------------------------------------------------------------
    return (
        <div className='flex justify-center items-center flex-col' style={{ minHeight: `calc(100vh - ${platform === "web" ? "4rem" : "0px"})` }}>
            <Card className='max-w-md m-3'>
                <CardBody>
                    {
                        platform !== "web" && (
                            <Button isIconOnly variant={"light"} className='m-3 mb-0' onClick={() => n(-1)}>
                                <WebIcon name={"west"} />
                            </Button>
                        )
                    }
                    <h1 className='text-3xl mb-0 m-3 font-bold'>
                        {t("introCvLayoutTitle")}
                    </h1>
                    <h1 className='text-xl m-3'>
                        {t("introCvLayoutMsg")}
                    </h1>
                    <div className="flex flex-wrap justify-center gap-5">
                        {
                            modelCatalogue.filter(e => ["london", "shanghai", "tokyo", "dublin"].includes(e.code)).map(e => (
                                <a
                                    key={e.code}
                                    className={`relative border-4 border-${cvLayout === e.category ? "primary" : "default"} rounded-3xl p-1`}
                                    href='#'
                                    onClick={() => setCvLayout(e.category)}
                                >
                                    <img alt={e.category} src={models[e.code]} className="object-cover rounded-2xl relative" style={{ width: 150}} />
                                </a>
                            ))
                        }
                    </div>
                </CardBody>
            </Card>
        </div>
    )
}

export default WebIntroCvLayout