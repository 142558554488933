import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Card, CardBody, Button } from "@nextui-org/react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { httpsCallable } from 'firebase/functions';
import { doc, collection, setDoc } from "firebase/firestore";
import { getBoolean } from 'firebase/remote-config';

import { base64FileToJson, createCvObject, dataToJson, getEnglishLabelFromLanguageCode } from "cvpop-utility-sdk";
import { setCv } from "cvpop-redux-sdk";
import { lotties } from "cvpop-assets-sdk"
import { t } from "cvpop-localization-sdk";

import Lottie from 'react-lottie';

import { createFbId, errorHandler, fileToBase64 } from '../../../controllers/app';
import { db, auth, functions, remoteConfig } from '../../../controllers/firebase';

import WebIcon from '../../atoms/WebIcon';


const WebIntroSpinner = () => {

    // ------------------------------------------------------------------------------------------------------
    const { user } = useSelector(state => state.userReducer)
    const { platform } = useParams()
    const { cvFile } = useLocation().state || {}

    // ------------------------------------------------------------------------------------------------------
    const d = useDispatch()
    const n = useNavigate()
    const fbuser = auth.currentUser

    // ------------------------------------------------------------------------------------------------------
    const { professionalTitle, language } = user

    // ------------------------------------------------------------------------------------------------------
    const isOpenAIOn = useMemo(() => getBoolean(remoteConfig, "openAIOn"), [])

    const cvApi = useMemo(() => httpsCallable(functions, 'createcv', { timeout: 120000 }), []);
    const pdfApi = useMemo(() => httpsCallable(functions, 'createcvfrompdf', { timeout: 180000 }), []);
    const docApi = useMemo(() => httpsCallable(functions, 'createcvfromdocx', { timeout: 180000 }), []);

    const deltaDivider = useMemo(() => !!cvFile ? 15 : 5, [cvFile])
    const maxProgress = useMemo(() => isOpenAIOn ? 95 : 100, [isOpenAIOn])

    const hLng = useMemo(() => getEnglishLabelFromLanguageCode(language), [language])

    // ------------------------------------------------------------------------------------------------------
    const [progress, setProgress] = useState(0)


    // ------------------------------------------------------------------------------------------------------
    const createOrImportCv = useCallback(async () => {

        const { type } = cvFile || {}
        const ref = doc(collection(db, "00_RESUMES_00"));

        let cv = null;

        if (cvFile) { // if file imported, try to import or fallback on automated cv
            try {

                const base64Data = await fileToBase64(cvFile)
                const { importedCv } = await base64FileToJson(user, base64Data.split(",")[1], type, type === "application/pdf" ? pdfApi : docApi, createFbId, atob)
                cv = createCvObject(fbuser, user, ref.id, importedCv, "createdByImport");

            } catch (e) {
                errorHandler("err_import_cv_onboarding", e, true)
                if (isOpenAIOn)
                    try {
                        const { data } = await cvApi({ jobTitle: professionalTitle, hLng })
                        cv = createCvObject(fbuser, user, ref.id, dataToJson(data, createFbId), "createdByJobTitle", { jobTitle: professionalTitle });
                    } catch (e) {
                        errorHandler("err_create_cv_onboarding", e, true)
                    }

            }
        }

        if (!cvFile && isOpenAIOn) { // if not file imported create auto cv
            try {
                const { data } = await cvApi({ jobTitle: professionalTitle, hLng })
                cv = createCvObject(fbuser, user, ref.id, dataToJson(data, createFbId), "createdByJobTitle", { jobTitle: professionalTitle });
            } catch (e) {
                errorHandler("err_create_cv_onboarding", e, true)
            }
        }

        if (!cv) return n(`/app/${platform}/onboarding/features`)
        d(setCv({ cv, ignoreListener: true }))
        setDoc(doc(db, "00_RESUMES_00", ref.id), cv)
            .catch(e => errorHandler("err_create_or_import_cv_onboading", e, true))
            .finally(() => n(`/app/${platform}/onboarding/features`))

    }, [fbuser, user, hLng, platform, professionalTitle, cvFile, d, n, docApi, cvApi, pdfApi])

    // ------------------------------------------------------------------------------------------------------
    useEffect(() => { createOrImportCv() }, [createOrImportCv])

    useEffect(() => {
        if (progress >= 100) {
            setTimeout(() => n(`/app/${platform}/onboarding/features`), 500)
            return
        }

        const interval = setInterval(() => {
            const delta = Math.random() / deltaDivider;
            setProgress((progress + delta) > maxProgress ? maxProgress : progress + delta)
        }, 10);

        return () => clearInterval(interval);
    }, [progress, deltaDivider, maxProgress, platform, n])

    // ------------------------------------------------------------------------------------------------------
    return (

        <div className='flex justify-center items-center flex-col' style={{ minHeight: `calc(100vh - ${platform === "web" ? "4rem" : "0px"})` }}>
            <Card className='max-w-md m-3'>
                {
                    platform !== "web" && (
                        <Button isIconOnly variant={"light"} className='m-3 mb-0' onClick={() => n(-1)}>
                            <WebIcon name={"west"} />
                        </Button>
                    )
                }
                <CardBody>
                    <div className="max-w-80 self-center">
                        <Lottie options={{ animationData: lotties.configure }} autoPlay loop style={{ cursor: "default" }} />
                    </div>
                    <p className="font-sans font-bold text-2xl text-center mt-3">{t("personalizingYourExperience")}</p>
                    <p className="font-sans text-center mt-3">{t("introSpinnerMsg")}</p>

                    {
                        progress < 100 ? (
                            <div className='mt-3 rounded-xl text-center bg-default'>
                                <div className='py-2 rounded-xl text-small truncate bg-primary text-white' style={{ width: `${progress}%` }}>
                                    <span>{progress < 100 ? `${parseInt(progress)}%` : t("continue")}</span>
                                </div>
                            </div>
                        ) : (
                            <Button
                                color="primary"
                                className='mt-3'
                                onClick={() => progress >= 100 && n(`/app/${platform}/onboarding/features`)}
                            >
                                {t("continue")}
                            </Button>
                        )
                    }


                </CardBody>
            </Card>
        </div>



    )
}

export default WebIntroSpinner