import './controllers/firebase.js'
import './controllers/lemon.js'
import './index.css'

import 'moment/min/locales';

import React from 'react';
import ReactDOM from 'react-dom/client';

import WebApp from './WebApp';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <WebApp />
);