import React, { useCallback } from 'react';
import { Card, CardBody, Button } from "@nextui-org/react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { produce } from "immer"

import { setUser } from 'cvpop-redux-sdk';
import { t } from "cvpop-localization-sdk";
import { onboardingColorCatalogue } from 'cvpop-constants-sdk';

import WebIcon from '../../atoms/WebIcon';


const WebIntroColor = () => {

    // ------------------------------------------------------------------------------------------------------
    const { user } = useSelector(state => state.userReducer)
    const { platform } = useParams()

    // ------------------------------------------------------------------------------------------------------
    const d = useDispatch()
    const n = useNavigate()

    // ------------------------------------------------------------------------------------------------------
    const { color } = user.introChoices || {};

    // ------------------------------------------------------------------------------------------------------
    const setColor = useCallback(o => {

        d(setUser({ user: produce(user, d => { d.introChoices = { ...d.introChoices, color: o } }) }))
        n(`/app/${platform}/onboarding/cv-layout`)

    }, [user, platform, d, n])

    // ------------------------------------------------------------------------------------------------------
    return (
        <div className='flex justify-center items-center flex-col' style={{ minHeight: `calc(100vh - ${platform === "web" ? "4rem" : "0px"})` }}>
            <Card className='max-w-md m-3'>
                <CardBody>
                    {
                        platform !== "web" && (
                            <Button isIconOnly variant={"light"} className='m-3 mb-0' onClick={() => n(-1)}>
                                <WebIcon name={"west"} />
                            </Button>
                        )
                    }
                    <h1 className='text-3xl mb-0 m-3 font-bold'>
                        {t("introColorTitle")}
                    </h1>
                    <h1 className='text-xl m-3'>
                        {t("introColorMsg")}
                    </h1>
                    <div className="flex flex-wrap justify-center gap-5">
                        {
                            onboardingColorCatalogue.map(e => (
                                <div key={`color${e}`} className={`relative border-4 border-${color === e ? "primary" : "default"} rounded-3xl p-1.5`}>
                                    <Card shadow={"none"} isPressable onPress={() => setColor(e)}>
                                        <CardBody className="p-0" style={{ backgroundColor: e, width: 55, height: 55 }} />
                                    </Card>
                                </div>
                            ))
                        }
                    </div>
                </CardBody>
            </Card>
        </div>
    )
}

export default WebIntroColor