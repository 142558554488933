import { useCallback, useMemo, useRef, useState } from "react"
import { Button, Dropdown, DropdownTrigger, DropdownMenu, DropdownItem, Card, CardBody } from "@nextui-org/react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { doc, collection, setDoc } from "firebase/firestore";
import { httpsCallable } from 'firebase/functions';
import { getBoolean } from "firebase/remote-config";
import { produce } from "immer"

import { setCv, setSpin, setUser } from "cvpop-redux-sdk";
import { base64FileToJson, createCvObject, dataToJson, getEnglishLabelFromLanguageCode, isProUser } from "cvpop-utility-sdk";
import { t } from "cvpop-localization-sdk"

import _ from "lodash"

import { createFbId, errorHandler, fileToBase64, goToPaywall, showAlert } from "../../../controllers/app";
import { auth, db, functions, remoteConfig } from "../../../controllers/firebase";
import { useColorScheme } from "../../../controllers/hooks";

import WebIcon from "../../atoms/WebIcon";


const WebAddCVButton = ({ type }) => {

    // ------------------------------------------------------------------------------------------------------------------------
    const { cvList } = useSelector(state => state.cvReducer)
    const { user } = useSelector(state => state.userReducer)
    const { offering } = useSelector(state => state.appReducer)
    const { platform } = useParams()

    // ------------------------------------------------------------------------------------------------------------------------
    const { language, professionalTitle } = user || {}

    // ------------------------------------------------------------------------------------------------------------------------
    const isPro = useMemo(() => isProUser(user), [user])
    const cvApi = useMemo(() => httpsCallable(functions, 'createcv', { timeout: 120000 }), []);
    const pdfApi = useMemo(() => httpsCallable(functions, 'createcvfrompdf', { timeout: 180000 }), []);
    const docApi = useMemo(() => httpsCallable(functions, 'createcvfromdocx', { timeout: 180000 }), []);
    const recoverApi = useMemo(() => httpsCallable(functions, 'sendrecovercvrequest'), []);

    const isCvOn = useMemo(() => (getBoolean(remoteConfig, "openAIOn")), [])
    const hLng = useMemo(() => getEnglishLabelFromLanguageCode(language), [language])

    // ------------------------------------------------------------------------------------------------------------------------
    const d = useDispatch()
    const n = useNavigate()
    const cs = useColorScheme()
    const inputRef = useRef()
    const fbuser = auth.currentUser

    // ------------------------------------------------------------------------------------------------------------------------
    const [recoverLoading, setRecoverLoading] = useState(false)

    // ------------------------------------------------------------------------------------------------------------------------
    const createCv = useCallback(async (id, cvFile) => {

        if (id === "create") {
            const ref = doc(collection(db, "00_RESUMES_00"));
            const newCv = createCvObject(fbuser, user, ref.id);
            d(setCv({ cv: newCv, ignoreListener: true }))
            return setDoc(doc(db, "00_RESUMES_00", ref.id), newCv)
                .then(() => n(`/app/${platform}/cv/edit`))
                .catch(e => errorHandler("err_create_new_cv", e))
        }

        if (id === "createAuto") {
            if (!isPro) return goToPaywall(offering, platform, n)

            let jobTitle = professionalTitle

            if (!jobTitle) {
                jobTitle = window.prompt(`${t('jobTitle')}\n\n${t('jobTitleMissMsg')}`)
                if (!jobTitle) return;
                d(setUser({ user: produce(user, d => { d.professionalTitle = jobTitle }) }))
            }

            d(setSpin(true))
            cvApi({ jobTitle, hLng })
                .then(({ data }) => {
                    const ref = doc(collection(db, "00_RESUMES_00"));
                    const cv = _.merge(
                        createCvObject(fbuser, { ...user, professionalTitle: jobTitle }, ref.id),
                        dataToJson(data, createFbId)
                    )
                    d(setCv({ cv, ignoreListener: true }))
                    return setDoc(doc(db, "00_RESUMES_00", ref.id), cv)
                })
                .then(() => n(`/app/${platform}/cv/edit`))
                .catch(e => errorHandler("err_create_cv", e))
                .finally(() => d(setSpin(false)))
        }

        if (id === "import") {

            try {

                d(setSpin(true))
                const base64 = await fileToBase64(cvFile)

                const { importedCv, removeItems } = await base64FileToJson(user, base64.split(",")[1], cvFile.type, cvFile.type === "application/pdf" ? pdfApi : docApi, createFbId, atob)

                // if hase photo resize it
                // if (importedPhoto && Platform.OS === "ios") {
                //     const { base64, mime } = importedPhoto
                //     const path = `${fs.dirs.CacheDir}/importedPhoto.${mime.replace("image/", "")}`
                //     try {
                //         await fs.writeFile(path, base64, 'base64')
                //         const image = await ImagePicker.openCropper({ ...photoImagePickerOptions, cropperCancelText: t('skip'), path })
                //         const newPhoto = { "MimeType": image.mime, "Data": image.data, "Metadata": [{ "Key": "dimension", "Value": "300x400" }] }
                //         importedCv.SkillsPassport.LearnerInfo.Identification.Photo = newPhoto
                //         fs.unlink(path)
                //     } catch (e) {
                //         errorHandler("err_import_cv_image", e, true)
                //     }
                // }

                const ref = doc(collection(db, "00_RESUMES_00"));
                const newCv = createCvObject(fbuser, user, ref.id, importedCv, "createdByImport");
                await setDoc(doc(db, "00_RESUMES_00", ref.id), newCv)

                d(setCv({ cv: newCv, ignoreListener: true }))
                d(setSpin(false))

                // if hase removed items tell to the user
                if (removeItems) await showAlert(t('ignoredInfo'), t('ignoredInfoMsg'))

                n(`/app/${platform}/cv/style`)

            } catch (e) {
                d(setSpin(false))
                errorHandler("err_create_cv", e)
            }

        }

    }, [fbuser, user, docApi, pdfApi, cvApi, d, n])

    const sendRecoverCvRequest = useCallback(async () => {

        const email = (window.prompt(`${t('recoverCvTitle')}\n\n${t('recoverCvMsg')}`) || "").trim()
        if (!email) return;

        setRecoverLoading(true)
        recoverApi({ email, locale: user.language })
            .then(({ data }) => showAlert(t(`recoverCv${data === "no-cv" ? "NoDataTitle" : "ReqSent"}`), t(`recoverCv${data === "no-cv" ? "NoDataMsg" : "ReqSentMsg"}`)))
            .catch(e => errorHandler("err_send_recover_cv_request", e))
            .finally(() => setRecoverLoading(false))
    }, [user])

    // ------------------------------------------------------------------------------------------------------------------------
    const renderCoreButton = useCallback(() => type === "card" ? (
        <Card isPressable onClick={() => !isPro && goToPaywall(offering, platform, n)} className="h-full">
            <CardBody className="items-center justify-center">
                <span className="material-symbols-outlined filled text-6xl text-primary">add_circle</span>
                {!isPro && (<span className="font-semibold text-yellow-500 text-xs">({t("premium")})</span>)}
            </CardBody>
        </Card>
    ) : (
        <Button color={"primary"} className="mt-4">{t("add")}</Button>
    ), [type, isPro, offering])


    // ------------------------------------------------------------------------------------------------------------------------
    if (cvList.length >= 10) return null
    if (!isPro && cvList.length > 0) return renderCoreButton()
    return (
        <div className="flex flex-col">
            {
                !isPro && cvList.length > 0 ? renderCoreButton() : (
                    <Dropdown backdrop={"blur"} className={`${cs} text-foreground`}>
                        <DropdownTrigger>
                            {renderCoreButton()}
                        </DropdownTrigger>

                        <DropdownMenu
                            color={"primary"}
                            aria-label="Static Actions"
                            onAction={k => {
                                if (k === "create") return createCv("create")
                                if (k === "createAuto") return createCv("createAuto")
                                if (k === "import") {
                                    if (!inputRef.current) return
                                    inputRef.current.value = ""
                                    inputRef.current.click()
                                }
                            }}
                        >
                            <DropdownItem key={"create"} title={t("create")} endContent={<WebIcon name={"note_add"} />} />

                            {
                                isCvOn && (
                                    <DropdownItem
                                        key={"createAuto"}
                                        title={t('generateAuto')}
                                        endContent={<WebIcon name={isPro ? "contract_edit" : "workspace_premium"} className={!isPro && `text-yellow-400 filled`} />}
                                    />
                                )
                            }

                            <DropdownItem key={"import"} title={t("import")} endContent={<WebIcon name={"upload_file"} />} />
                        </DropdownMenu>
                    </Dropdown >
                )
            }

            {
                cvList.length < 10 && (isPro || cvList.length === 0) && (
                    <Button variant={"light"} color={"primary"} isLoading={recoverLoading} onClick={sendRecoverCvRequest} className="mt-3">
                        {t("recoverCvQuestion")}
                    </Button>
                )
            }


            <input
                type="file"
                className='hidden'
                accept="application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                ref={inputRef}
                onChange={({ target }) => {
                    if (target.files.length === 0) return
                    if (!["application/pdf", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"].includes(target.files[0].type))
                        return showAlert(t("errTitle"), t("notSupportedTypeFile"))

                    createCv("import", target.files[0])
                }}
            />

        </div>
    )

}

export default WebAddCVButton