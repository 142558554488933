import React, { useCallback, useMemo } from 'react';
import { Listbox, ListboxItem, Card, CardBody, Button } from "@nextui-org/react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { produce } from "immer"

import { setUser } from 'cvpop-redux-sdk';
import { onboardingSeniorityCatalogue, onboardingGoalCatalogue, onboardingTimeCatalogue, onboardingPersonalityCatalogue } from "cvpop-constants-sdk";
import { t } from "cvpop-localization-sdk";

import _ from "lodash"

import WebIcon from '../../atoms/WebIcon';


const WebIntroCommonItemList = ({ type }) => {

    // ------------------------------------------------------------------------------------------------------
    const { platform } = useParams()
    const { user } = useSelector(state => state.userReducer)

    // ------------------------------------------------------------------------------------------------------
    const d = useDispatch()
    const n = useNavigate()

    // ------------------------------------------------------------------------------------------------------
    const itemList = useMemo(() => {
        if (type === "seniority") return onboardingSeniorityCatalogue
        if (type === "goal") return onboardingGoalCatalogue
        if (type === "timing") return onboardingTimeCatalogue
        if (type === "personality") return onboardingPersonalityCatalogue
    }, [type])

    // ------------------------------------------------------------------------------------------------------
    const setItem = useCallback(o => {
        d(setUser({ user: produce(user, d => { d.introChoices = { ...d.introChoices, [type]: o } }) }))

        if (type === "seniority") n(`/app/${platform}/onboarding/goal`)
        if (type === "goal") n(`/app/${platform}/onboarding/timing`)
        if (type === "timing") n(`/app/${platform}/onboarding/personality`)
        if (type === "personality") n(`/app/${platform}/onboarding/color`)

    }, [d, n, type, platform, user])

    // ------------------------------------------------------------------------------------------------------
    return (
        <div className='flex justify-center items-center flex-col' style={{ minHeight: `calc(100vh - ${platform === "web" ? "4rem" : "0px"})` }}>
            <Card className='max-w-md m-3'>
                <CardBody>
                    {
                        platform !== "web" && (
                            <Button isIconOnly variant={"light"} className='m-3 mb-0' onClick={() => n(-1)}>
                                <WebIcon name={"west"} />
                            </Button>
                        )
                    }
                    <h1 className='text-3xl mb-0 m-3 font-bold'>
                        {t(`whatsYour${_.capitalize(type)}`)}
                    </h1>
                    {
                        type === "personality" && (
                            <h1 className='text-xl m-3'>
                                {t("introPersonalityMsg")}
                            </h1>
                        )
                    }
                    <Listbox
                        color='primary'
                        disallowEmptySelection
                        aria-label={t("introPersonalityMsg")}
                        defaultSelectedKeys={[user.introChoices?.[type] || ""]}
                        selectionMode="single"
                        itemClasses={{ title: "whitespace-normal" }}
                    >
                        {
                            itemList.map(e => (
                                <ListboxItem
                                    key={e}
                                    title={t(`intro${_.capitalize(type)}${e}`)}
                                    description={!["timing", "personality"].includes(type) && t(`intro${_.capitalize(type)}${e}Msg`)}
                                    onPress={() => setItem(e)}
                                />
                            ))
                        }
                    </Listbox>
                </CardBody>
            </Card>
        </div>
    )
};


export default WebIntroCommonItemList